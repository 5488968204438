// Serbisch

module.exports = {
  "main_navigation": {
    "login": "Prijava",
    "registration": "Registracija",
    "courses": "Moje obuke",
    "coursesDetail": "Detalji obuke",
    "catalog": "Katalog",
    "learningProfile": "Profil učenja",
    "contact": "Kontakt",
    "profile": "Profil",
    "pwLost": "Zaboravili ste lozinku?",
    "about": "Impresum",
    "terms_and_conditions": "Uslovi korišćenja / zaštita podataka",
    "logout": "Odjava",
    "faq": "Najčešće postavljena pitanja",
    "trainingmanager": "Zahtevi",
    "trainingmanagerrequest": "Zahtev",
    "trainingmanagermanage": "Upravljanje",
    "trainingmanagermanagedetail": "Detalj"
  },
  "general": {
    'offline': {
      'headline': 'Niste na mreži!',
      'text': 'Ova aplikacija treba aktivnu internetsku vezu da bi ispravno radila.',
    },
    "year": "Godina",
    "month": "Mesec",
    "noResult": "Nije pronađen nijedan rezultat.",
    "months": {
      "10": "Oktobar",
      "11": "Novembar",
      "12": "Decembar",
      "01": "Januar",
      "02": "Februar",
      "03": "Mart",
      "04": "April",
      "05": "Maj",
      "06": "Jun",
      "07": "Jul",
      "08": "Avgust",
      "09": "Septembar"
    },
    "category": "Kategorija",
    "calendar": "Kalendar",
    "search": "Pretraga",
    "searchError": "Unesite najmanje tri znaka.",
    "showLess": "Prikaži manje",
    "showMore": "Prikaži više",
    'pagination': {
      'next': 'Sljedeći',
      'prev': 'Prethodni',
      'separator': 'od',
      'pageSize': 'Stavke po stranici'
    }
  },
  "maintenance": {
    "title": "Radovi održavanja",
    "text": "Zbog radova održavanja, akademija trenutno nije dostupna. Molimo vas da pokušate kasnije."
  },
  "password": {
    "requirements": {
      "title": "Smernice za lozinku",
      "minimalLength": "Dužina mora biti najmanje #0.",
      "mandantName": "Ne smete koristiti pojam \"#0“.",
      "names": "Lozinka ne sme istovremeno biti i ime i prezime.",
      "userName": "Lozinka ne sme sadržavati tvoje korisničko ime.",
      "userNameEqual": "Lozinka ne sme sadržavati tvoje korisničko ime.",
      "bigLetters": "Mora/moraju sadržavati najmanje #0 velikih slova.",
      "smallLetters": "Mora/moraju sadržavati najmanje #0 malih slova.",
      "digits": "Mora/moraju sadržavati najmanje #0 brojeva.",
      "sameLetters": "Ne sme da sledi više od #0 znakova jedan za drugim.",
      "oldPassword": "Ne sme biti stara lozinka.",
      "birthday": "Lozinka ne sme odgovarati vlastitom datumu rođenja.",
      "minSymbolsPolicy": "Mora/moraju sadržavati najmanje #0 posebnih znakova.",
      "minPasswordLength": "Dužina lozinke mora biti najmanje #0 znakova.",
      "maxPasswordAttempts": "Imate još #0 preostalih pokušaja da pravilno unesete lozinku."
    }
  },
  'booking': {
    'cancel': {
      'title': 'Cancel booking',
      'confirm': 'are you sure you want to cancel this booking?'
    }
  },
  "pages": {
    "notFound": {
      "headline": "Žao nam je!",
      "text1": "Tražena stranica nije pronađena",
      "text2": "Koristite taster za povratak ili se vratite na početnu stranicu.",
      "button": "Nazad na početnu stranicu"
    },
    "userNotFound": {
      "headline": "Prilikom Vaše prijave došlo je do greške!",
      "text": "Nije moguće pronaći korisnika vaše organizacije. Pokušajte ponovo kasnije ili kontaktirajte Vašu osobu za kontakt ili nadređenog.",
      "button": "Pokušajte ponovo"
    },
    "courseCalendar": {
      "expired": "Isteklo",
      "soonExpired": "Uskoro ističe",
      'expiredMeetings': 'Prošli sastanci'
    },
    "profile": {
      "userdata": "Korisnički podaci",
      "password": "Lozinka",
      "genderLab": "Oslovljavanje",
      "gender": {
        "men": "Gospodin",
        "woman": "Gospođa",
        "diverse": "Ostalo",
        "unknown": "Nije precizirano"
      },
      "firstname": "Ime",
      "lastname": "Prezime",
      "email": "E-pošta",
      "strasse": "Ulica / br.",
      "city": "Poštanski broj / mesto",
      "phone": "Telefon",
      "fax": "Faks",
      "position": "Pozicija",
      "department": "Odeljenje",
      "required": "Obavezno polje",
      "oldPassword": "Stara lozinka",
      "newPassword": "Nova lozinka",
      "newPasswordRep": "Ponavljanje",
      "company": "Kompanija",
      "language": "Jezik"
    },
    "catalog": {
      "back": "Nazad",
      "bookingDialog": {
        "notice": "Ovde navedite razlog vašeg zahteva za obuku"
      }
    },
    "contact": {
      "info": "Molimo unesite vaš zahtev u polje za poruke. Ako mislite na određenu obuku, molimo vas da je navedete: Vaša poruka će na taj način biti prosleđena na pravo mesto.",
      "trainingSelection": "Izbor obuke",
      "yourMessage": "Vaša poruka",
      "success": "Vaša poruka je uspešno poslata!",
      "otherReason": "Opšti zahtev za kontakt",
      "otherReasonSubject": "Ostali zahtevi",
      "chooseReason": "Molimo da odaberete"
    },
    "learningProfile": {
      "title": "Profil učenja",
      "unit": "Tematske jedinice",
      "points": "Ukupan broj bodova",
      "history": "Hronologija učenja",
      "restoreFilter": "Resetujte filter",
      "certificate": "Sertifikat",
      "downloadAll": "Preuzmi sve",
      "withoutCategory": "Bez kategorije",
      "tooltip01": "Vi ste apsolvirali",
      "tooltip02": "od",
      "tooltip03": "tematske jedinice.",
      "gamification": {
        "badge": {
          "title": "Nagrade",
          "congratulations": "Srdačne čestitke!",
          "achieved": "Ponovo ste nagrađeni!"
        }
      }
    },
    "courseDetail": {
      "title": "Detalji obuke",
      'titleProject': 'detalje projekta',
      "back": "Nazad na listu",
      "progress": "Napredak",
      "expiry": "važi do",
      "classroomNo": "Broj obuke",
      "contactPerson": "Osoba za kontakt",
      "targetGroup": "Ciljna grupa",
      "bonusPoints": "Bonus bodovi",
      "classroomContent": "Sadržaji obuke",
      "webinarLight": "Informacije o vebinaru",
      "specials": "Karakteristike",
      "stornoConditions": "Uslovi za storniranje",
      "meeting": "Termini",
      'meetingBooking': 'rezervirajte termine',
      'meetingRequests': 'zatražiti sastanke',
      'freeDay': 'Free day',
      'freeDayInfo': 'Slobodni dani nisu navedeni gore\n',
      "speaker": "Trener / referent",
      "location": "Mesto obuke",
      "requested": "ZAHTEV POSLAT",
      "waitlist": "LISTA ČEKANJA",
      "fullyBooked": "SVE JE POPUNJENO",
      "edit": "Obrada",
      "webinarSpeaker": "Referent",
      "docsAndLinks": "Dokumenti i linkovi",
      "links": "Linkovi",
      "webinar": {
        "host": "Otvori vebinar",
        "state": {
          "ended": "Vebinar je završen",
          "beforeStart": "Vebinar počinje u "
        }
      },
      "sidebar": {
        "price": "Cena po ispitu",
        "duration": "Trajanje obuke",
        "to": "do",
        "numberOfParticipants": "Broj učesnika",
        "status": "Status rezervacije",
        'totalStatus': 'cjelokupni status',
        'contentStatus': 'status obuke'
      },
      "bookingInformation": {
        "title": "Informacije o rezervaciji",
        "note": "Napomena",
        "accounts": "Nosilac troškova",
        "invoiceRecipient": "Primalac računa",
        "invoiceAddress": "Adresa računa",
        "food": "Preferencije za ishranu",
        "arrival": "Dolazak",
        "arrivalType": {
          "none": "Nije precizirano",
          "car": "Auto",
          "train": "Voz",
          "plane": "Avion"
        },
        "arrivalDate": "Datum dolaska",
        "departure": "Odlaska",
        "departureDate": "Datum odlaska",
        "flightNumber": "Broj leta",
        "trainNumber": "Broj voza",
        "railwayStation": "Železnička stanica",
        'placeholder': {
          'departure_airport': 'Išvykimo oro uostas',
          'destination_airport': 'Paskirties oro uostas',
          'departure_trainstation': 'Išvykimo stotis',
          'destination_trainstation': 'Paskirties stotis',
          'departure_car': 'Išvykimo vieta',
          'destination_car': 'Paskirties vieta',
          'from': 'iš',
          'to': 'į'
        },
        'weekdays': [
          'Mon',
          'Tue',
          'Wed',
          'Thu',
          'Fri',
          'Sat',
          'Sun'
        ],
        'months': [
          "Januar",
          "Februar",
          "Mart",
          "April",
          "Maj",
          "Jun",
          "Jul",
          "Avgust",
          "Septembar",
          "Oktobar",
          "Novembar",
          "Decembar"
        ]
      },
      "reserve": {
        "title": "Ne možete uskladiti termin(e)?",
        "text": "Naznačite šta vam je u interesu! Zatim ćete biti informisani o mogućim novim terminima.",
        "button": "Upišite svoje interesovanje",
        "prebooked": {
          "text": "Upisali ste vaša interesovanja za ovu obuku."
        }
      },
      'downloads': {
        'modalHeadline': 'Prenosi',
        'sidebarLink': 'Dokument(i)',
      }
    },
    "course": {
      "type": {
        "0": "Onlajn obuka",
        "1": "Onlajn obuka",
        "2": "Onlajn obuka",
        "3": "Onlajn obuka",
        "4": "Onlajn obuka",
        "5": "Onlajn obuka",
        "6": "Obuka uživo",
        "7": "Vebinar",
        "8": "Projekat",
        "undefined": "-"
      },
      "status": {
        "open": "Otvoreno",
        'sent': 'Sent',
        "passed": "Položeno",
        'sent': 'Oсетити',
        "failed": "Nije položeno",
        'participated': 'Participated',
        'notParticipated': 'Not participated',
        "expired": "Isteklo",
        "soonExpired": "Uskoro ističe",
        "booked": "Rezervisano",
        "started": "U obradi",
        "upcoming": "Predstoji",
        "running": "U toku",
        "completed": "Završeno",
        "notyet": "Još nije dostupno"
      },
      "notification": {
        "success1": "Položili ste obuku ",
        "success2": "!"
      },
      'warning': {
        'headline': 'Pažnja!',
        'body': {
          '1': 'Odabrali ste jezik koji nije prošli put za ovu obuku.',
          '2': 'Ako nastavite i kliknete na "Pokreni trening", napredak se resetira.',
        },
        'buttons': {
          'start': 'Pokreni trening',
          'startPrevious': 'Odaberite prethodni jezik',
        }
      },
      "statusLabel": {
        "training": "Obuka",
        'content': 'Content',
        'attendance': 'Attendance',
        "exam": "Ispit",
        "survey": "Anketa",
        "efficacyControl": "Kontrola efikasnosti"
      },
      "start": {
        "survey": "Započni anketu",
        "exam": "Započni ispit",
        "efficacyControl": "Započni kontrolu efikasnosti"
      },
      "prefix": {
        "category": "Kategorija",
        "state": "Status",
        "type": "Tip obuke"
      },
      "noAssigned": "Nisu vam dodeljene obuke.",
      "noSolved": "Još niste apsolvirali nijednu obuku. U polju „Moje obuke“ započnite sa vašom prvom tematskom jedinicom!",
      "noAssignedBookable": "Nije vam dodeljena nijedna obuka koju je moguće rezervisati.",
      "noResult": "Nije pronađena nijedna obuka koja odgovara kriterijumima.",
      "loadingFailed": "Traženi zapisi podataka nisu mogli da se učitaju usled nepoznate greške!",
      "noResultInCategory": "Ovoj kategoriji nije dodeljena još nijedna obuka.",
      "meeting": {
        "noMeeting": "bez termina",
        "multipleMeetings": "različiti termini"
      },
      "project": {
        "courses": "Obuke"
      }
    },
    "login": {
      "invalidUsername": "Neuspešna prijava. Korisničko ime je pogrešno!",
      "invalidUser": "Neuspešna prijava. Korisničko ime / lozinka je pogrešna!",
      "invalidPassword": "Neuspešna prijava. Imate još #0 preostalih pokušaja da pravilno unesete lozinku.",
      "accountBlocked": "Vaš nalog je zaključan. Molimo vas da se obratite odgovornom zaposlenom.",
      "error": "Korisničko ime i/ili lozinka je pogrešna!",
      "empty": "Molimo popunite sva polja.",
      "back": "Nazad na prijavu",
      "description": "Unesite svoje korisničko ime ili svoju adresu e-pošte, kako bismo vam mogli ponovo poslati podatke za prijavu.",
      "password-reset": {
        "description": "Molimo da odaberete novu lozinku za vaš korisnički nalog.",
        "success": "Vaša lozinka je uspešno promenjena.",
        "error": "Unesene lozinke se ne poklapaju."
      },
      "placeholder": " Korisničko ime / adresa e-pošte",
      "button": "Zatraži podatke za prijavu",
      "success": "Vaša zahtev je uspešno poslat.",
      "errorForgot": "Nije pronađen nijedan korisnik koji odgovara navedenim kriterijumima.",
      "errorForgotSecurityQuestion": "Vaš odgovor je, nažalost, netačan!"
    },
    "registration": {
      "description": "Ako još uvek nemate podatke za prijavu, jednostavno se možete registrovati koristeći promotivni kod svoje kompanije. Odmah zatim ćete primiti e-poštu.",
      "actionCodeError": "Uneseni kod za registraciju ne važi.",
      "actionCodeLimitExceeded": "Maksimalan broj mogućih registracija za uneti promotivni kod je potrošen.",
      "registerDescription": "Molimo vas da popunite donji formular. Vaši podaci za prijavu biće vam poslati e-poštom za nekoliko minuta.",
      "title": "Vaša registracija za akademiju",
      "required": "Obavezno polje*",
      "emailError": "Adresa e-pošte koju ste naveli je već zauzeta.",
      "emailErrorNotAllowed": "Registracija nije moguća sa ovom adresom e-pošte.",
      "send": "Pošalji",
      "completeMessage": "Uspešno ste se registrovali za akademiju!"
    },
    "dataProtection": {
      "accept": "Potvrda",
      "acceptText": "Da, slažem se sa uslovima korišćenja / zaštitom podataka"
    },
    "securityQuestion": {
      "title": "Bezbednosno pitanje",
      "chooseQuestion": "Molimo da odaberete",
      "questionSelection": "Molimo da odaberete jedno pitanje",
      "yourAnswer": "Molimo unesite vaš odgovor",
      "description": "Odgovorite na bezbednosno pitanje i odaberite novu lozinku za vaš korisnički nalog."
    },
    "faq": {
      "title": "Najčešće postavljena pitanja"
    }
  },
  "sidebar": {
    "all": "Sve",
    "categories": "KATEGORIJE",
    "status": "STATUS",
    "courseType": "TIP OBUKE",
    "topics": "TEMATSKA PODRUČJA"
  },
  "placeholder": {
    "username": "Korisničko ime",
    "password": "Lozinka",
    "actionCode": "Promotivni kod"
  },
  "button": {
    "login": "PRIJAVA",
    "login-sso": "AKTIVNI DIREKTORIJUM-PRIJAVA",
    "send": "POŠALJI",
    "start_course": "START",
    "changePassword": "Promeni lozinku",
    "reset": "Resetuj",
    "save": "Sačuvaj",
    "request": "Zahtev",
    "storno": "Storniraj",
    "canceled": "Termin je storniran",
    "close": "Zatvori",
    "booking": "Rezerviši",
    "waitlist": "Lista čekanja",
    "start_webinar": "Započni vebinar",
    "open_webinar": "Otvori vebinar",
    'cancel': 'Otkaži',
    'confirm': 'Potvrdi'
  },
  "bookingState": {
    "0": "ZAHTEV POSLAT",
    "1": "REZERVISANO",
    "2": "LISTA ČEKANJA",
    "3": "STORNIRANO",
    "4": "STORNIRANO",
    "5": "ODBIJENO",
    "6": "REZERVISANO",
    "onlineTraining": {
      "0": "ZAHTEV POSLAT",
      "1": "REZERVISANO",
      "2": "ODBIJENO"
    }
  },
  "foodPreference": {
    "none": "Nema",
    "lactoseIntolerance": "Intolerancija na laktozu",
    "vegetarian": "Vegetarijanska ishrana",
    "vegan": "Veganska ishrana",
    "nutAllergy": "Alergija na orašaste plodove",
    "diabetic": "Dijabetes",
    "glutenFree": "Bez glutena",
    "kosher": "Košer",
    "halal": "Halal",
    "other": "Ostalo"
  },
  "notifications": {
    "error": "Nešto nije u redu!",
    "errors": {
      "missingInput": "Molimo popunite polja",
      "imageLoad": "Tražena slika nije mogla da se učita"
    },
    "success": {
      "book": {
        "meeting": "Uspešno ste rezervisali obuku uživo.",
        "webinar": "Uspešno ste rezervisali vebinar.",
        "course": "Uspešno ste rezervisali obuku.",
        "project": "Uspešno ste rezervisali projekat."
      },
      "request": {
        "meeting": "Vaš zahtev za obuku uživo je uspešno poslat.",
        "webinar": "Vaš zahtev za vebinar je uspešno poslat.",
        "course": "Vaš zahtev za obuku je uspešno poslat.",
        "project": "Vaš zahtev za projekat je uspešno poslat."
      },
      "profile": {
        "updateSuccess": "Uspešno ste ažurirali vaš profil!",
        "updatePasswordSuccess": "Uspešno ste ažurirali vašu lozinku!"
      },
      "securityQuestion": {
        "questionSave": "Vaše bezbednosno pitanje je sačuvano!"
      }
    }
  },
  "webservice": {
    "error": {
      "duplicate": "Rezervacija već postoji!",
      "noaccess": "Pristup nije dozvoljen.",
      "notimplementet": "Funkcija u ovom trenutku nije dostupna."
    },
    "success": {
      "booking": "Uspešno ste rezervisali.",
      "request": "Uspešno ste poslali zahtev.",
      "cancel": "Uspešno ste stornirali. ",
      "waitlist": {
        "add": "Stavljeni ste na listu čekanja"
      },
      "reservelist": {
        "add": "Stavljeni ste na listu za rezervacije"
      }
    },
    "courseCalendar": {
      "noDate": "Bez roka"
    }
  },
  "trainingmanager": {
    "headline": "Zahtev za usavršavanje",
    "headlinerequest": "Podneti zahtev",
    "bottonrequest": "Podneti zahtev",
    "buttonmanage": "Upravljanje zahtevima",
    "requestform": {
      "requester": "Podnosilac zahteva:",
      "date": "Datum:",
      "tab01": "Podneti zahtev",
      "tab02": "Zahtev za grupu/treću stranu",
      "tab01headline01": "Ime učesnika:",
      "tab01headline02": "Tema usavršavanja:",
      "tab01headline04": "Ime učesnika:",
      "tab01headline03": "Detalji kursa:",
      "tab01label01": "Oslovljavanje:",
      "tab01label02": "Ime:",
      "tab01label03": "Prezime:",
      "tab01label04": "Gospodin",
      "tab01label05": "Gospođa",
      "tab01label06": "Kategorija:",
      "tab01label07": "Titula*:",
      "tab01label08": "Kratak opis:",
      "tab01label09": "Ostalo:",
      "tab01label09O": "Ostalo",
      "tab01label10": "Nosilac troškova:",
      "tab01label11": "Termin (od/do): *",
      "tab01label12": "Broj kursa:",
      "tab01label13": "Cena:",
      "tab01label14": "Ponuđač:",
      "tab01label15": "Prilog:",
      "tab01label16": "Izaberite datoteku",
      "tab01label17": "Nije izabrana datoteka...",
      "tab01label18": "Poslati zahtev",
      "tab01label19": "Dodati lica",
      "tab01label20": "Odustati",
      "olheadline": "Dodati učesnika",
      "olheadline1": "Ime učesnika",
      "olbodytext": "U nastavku izaberite imena ostalih učesnika obuke.",
      "olerror": "Nisu pronađeni učesnici. Molimo da promenite parametre pretrage."
    },
    "manage": {
      "headline": "Upravljanje zahtevima",
      "headlineInfo": "Upiti zaposlenih",
      "bodytextInfo": "U nastavku ćete naći sve otvorene upite za usavršavanje sa zahtevom za obradu.",
      "newRequest": "Poslati vlastiti zahtev",
      "tableHeadline01": "Prijem zahteva",
      "tableHeadline02": "Podnosilac zahteva",
      "tableHeadline03": "Br. kursa",
      "tableHeadline04": "Titula",
      "tableHeadline05": "Termin",
      "tableHeadline06": "Cena",
      "tableMark": "Označiti sve",
      "buttonapprove": "Dati odobrenje",
      "buttondecline": "Odbiti odobrenje",
      "modal": {
        "refuse": {
          "headline": "Obavest - odbiti zahtev",
          "bodytext": "",
          "placeholder": "Molimo vas da unesete tekst...",
          "checkbox": "Odbiti zahtev bez obaveštenja",
          "button": "Pošalji",
          "approval": "Odobrenje je dato",
          "refuse": "Odobrenje odbijeno"
        }
      }
    }
  },
  'projectBookingModal': {
    'headlines': {
      'chooseMeeting': 'odaberite datum',
      'summaryMeeting': 'Pregled termina',
      'summary': 'Sažetak',
      'online': 'pregled',
      'mixed': 'pregled',
      'information': 'opcije rezervacije'
    },
    'description': 'Provjerite odabrane informacije o rezervaciji prije slanja.'
  },
  'multiModal': {
    'buttons': {
      'cancel': 'Prekid',
      'next': 'Nastaviti',
      'back': 'Povratak',
      'send': 'podnijeti'
    }
  }
}
