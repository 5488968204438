// Türkisch

module.exports = {
  "main_navigation": {
    "login": "Oturum aç",
    "registration": "Kayıt",
    "courses": "Eğitimlerim",
    "coursesDetail": "Eğitim detayları",
    "catalog": "Katalog",
    "learningProfile": "Öğrenme profili",
    "contact": "İletişim",
    "profile": "Profil",
    "pwLost": "Giriş bilgilerinizi mi unuttunuz?",
    "about": "Künye",
    "terms_and_conditions": "Kullanım Koşulları / Veri Gizliliği",
    "logout": "Çıkış",
    "faq": "SSS",
    "trainingmanager": "Başvurular",
    "trainingmanagerrequest": "Talep",
    "trainingmanagermanage": "Yönet",
    "trainingmanagermanagedetail": "Detay"
  },
  "general": {
    'offline': {
      'headline': 'Çevrimdışısınız!',
      'text': 'Bu uygulamanın düzgün çalışması için aktif bir internet bağlantısına ihtiyacı vardır.',
    },
    "year": "Yıl",
    "month": "Ay",
    "noResult": "Sonuç bulunamadı.",
    "months": {
      "10": "Ekim",
      "11": "Kasım",
      "12": "Aralık",
      "01": "Ocak",
      "02": "Şubat",
      "03": "Mart",
      "04": "Nisan",
      "05": "Mayıs",
      "06": "Haziran",
      "07": "Temmuz",
      "08": "Ağustos",
      "09": "Eylül"
    },
    "category": "Kategori",
    "calendar": "Takvim",
    "search": "Arama",
    "searchError": "Lütfen en az üç karakter girin.",
    "showLess": "Daha az göster",
    "showMore": "Daha fazla göster",
    'pagination': {
      'next': 'Sonraki',
      'prev': 'Öncesi',
      'separator': '/',
      'pageSize': 'Sayfa başına öğe'
    }
  },
  "maintenance": {
    "title": "Bakım çalışmaları",
    "text": "Bakım çalışmaları nedeniyle akademi şu anda servis dışı. Lütfen daha sonra tekrar deneyin."
  },
  "password": {
    "requirements": {
      "title": "Şifre yönetmelikleri",
      "minimalLength": "Uzunluk en az #0 olmalıdır.",
      "mandantName": "\"#0\" kelimesi kullanılamaz.",
      "names": "Şifre",
      "userName": "Şifre, kullanıcı adınızı içeremez.",
      "userNameEqual": "Şifre, kullanıcı adınızı içeremez.",
      "bigLetters": "En az #0 büyük harf kullanılmalıdır.",
      "smallLetters": "En az #0 küçük harf kullanılmalıdır.",
      "digits": "En az #0 rakam kullanılmalıdır.",
      "sameLetters": "Art arda #0 karakter kullanılamaz.",
      "oldPassword": "Yeni şifre, eskisi şifreyle aynı olamaz.",
      "birthday": "Şifre, kendi doğum tarihinizle aynı olamaz.",
      "minSymbolsPolicy": "En az #0 özel karakter kullanılmalıdır.",
      "minPasswordLength": "Şifrenin uzunluğu en az #0 karakter olmalıdır.",
      "maxPasswordAttempts": "Şifreyi doğru girmek için #0 denemeniz kaldı."
    }
  },
  'booking': {
    'cancel': {
      'title': 'Cancel booking',
      'confirm': 'are you sure you want to cancel this booking?'
    }
  },
  "pages": {
    "notFound": {
      "headline": "Özür dileriz!",
      "text1": "Talep edilen sayfa bulunamadı.",
      "text2": "Tarayıcınızın 'Geri' tuşunu kullanın veya ana sayfaya gidin.",
      "button": "Ana sayfaya geri git"
    },
    "userNotFound": {
      "headline": "Kayıt sırasında bir hata oluştu!",
      "text": "Kurum kullanıcınız bulunamadı. Lütfen daha sonra tekrar deneyin ya da yetkiliniz veya amiriniz ile iletişime geçin.",
      "button": "Tekrar dene"
    },
    "courseCalendar": {
      "expired": "Süre doldu",
      "soonExpired": "Süre yakında doluyor",
      'expiredMeetings': 'Geçmiş tarihler'
    },
    "profile": {
      "userdata": "Kullanıcı bilgileri",
      "password": "Şifre",
      "genderLab": "Hitap",
      "gender": {
        "men": "Bay",
        "woman": "Bayan",
        "diverse": "Diğer",
        "unknown": "Bilgi yok"
      },
      "firstname": "Adı",
      "lastname": "Soyadı",
      "email": "E-posta",
      "strasse": "Cadde / No.",
      "city": "Posta kodu / İl",
      "phone": "Telefon",
      "fax": "Faks",
      "position": "Pozisyon",
      "department": "Departman",
      "required": "Zorunlu alan",
      "oldPassword": "Eski şifre",
      "newPassword": "Yeni şifre",
      "newPasswordRep": "Tekrarla",
      "company": "Şirket",
      "language": "Dil"
    },
    "catalog": {
      "back": "Geri",
      "bookingDialog": {
        "notice": "Lütfen buraya eğitim talebi nedeninizi girin"
      }
    },
    "contact": {
      "info": "Lütfen talebinizi mesaj alanına girin. Belirli bir eğitimi kastediyorsanız, lütfen buraya belirtin: Böylece mesajınız doğru bölüme doğrudan iletilebilir.",
      "trainingSelection": "Eğitim seçimi",
      "yourMessage": "Mesajınız",
      "success": "Mesajınız başarıyla gönderildi!",
      "otherReason": "Genel iletişim talebi",
      "otherReasonSubject": "Başka talep",
      "chooseReason": "Lütfen seçin"
    },
    "learningProfile": {
      "title": "Öğrenme profili",
      "unit": "Öğrenme üniteleri",
      "points": "Toplam puan",
      "history": "Öğrenme geçmişi",
      "restoreFilter": "Filtreleri sıfırla",
      "certificate": "Sertifika",
      "downloadAll": "Tümünü indir",
      "withoutCategory": "Kategorisiz",
      "tooltip01": "Şu ana kadar",
      "tooltip02": "/",
      "tooltip03": "eğitim ünitesini tamamladınız.",
      "gamification": {
        "badge": {
          "title": "Ödüller",
          "congratulations": "Tebrikler!",
          "achieved": "Yeni bir ödül kazandınız!"
        }
      }
    },
    "courseDetail": {
      "title": "Eğitim detayları",
      'titleProject': 'Proje detayları',
      "back": "Listeye geri git",
      "progress": "İlerleme",
      "expiry": "son geçerlilik tarihi:",
      "classroomNo": "Eğitim numarası",
      "contactPerson": "Yetkili kişi",
      "targetGroup": "Hedef kitle",
      "bonusPoints": "Bonus puanlar",
      "classroomContent": "Eğitim içerikleri",
      "webinarLight": "Web semineri bilgileri",
      "specials": "Özellikler",
      "stornoConditions": "İptal koşulları",
      "meeting": "Tarihler",
      'meetingBooking': 'randevu almak',
      'meetingRequests': 'randevu talep etmek',
      'freeDay': 'Free day',
      'freeDayInfo': 'Ücretsiz günler yukarıda listelenmemiş\n',
      "speaker": "Eğitmen / Danışman",
      "location": "Eğitim yeri",
      "requested": "TALEP EDİLDİ",
      "waitlist": "BEKLEME LİSTESİ",
      "fullyBooked": "DOLU",
      "edit": "Düzenle",
      "webinarSpeaker": "Danışman",
      "docsAndLinks": "Belgeler ve bağlantılar",
      "links": "Bağlantılar",
      "webinar": {
        "host": "Web semineri oluştur",
        "state": {
          "ended": "Web semineri sonlandı",
          "beforeStart": "Web seminerinin başlangıç tarihi: "
        }
      },
      "sidebar": {
        "price": "Kişi başı fiyat",
        "duration": "Öğrenme süresi",
        "to": "geçerlilik:",
        "numberOfParticipants": "Katılımcı sayısı",
        "status": "Rezervasyon durumu",
        'totalStatus': 'Genel durum',
        'contentStatus': 'Eğitim durumu'
      },
      "bookingInformation": {
        "title": "Rezervasyon bilgisi",
        "note": "Not",
        "accounts": "Ödeme noktası",
        "invoiceRecipient": "Fatura alıcısı",
        "invoiceAddress": "Fatura adresi",
        "food": "Beslenme tercihi",
        "arrival": "Varış",
        "arrivalType": {
          "none": "Bilgi yok",
          "car": "Araba",
          "train": "Tren",
          "plane": "Uçak"
        },
        "arrivalDate": "Varış tarihi",
        "departure": "kalkış",
        "departureDate": "Dönüş tarihi",
        "flightNumber": "Uçuş numarası",
        "trainNumber": "Tren numarası",
        "railwayStation": "Tren garı",
        'placeholder': {
          'departure_airport': 'Kalkış havaalanı',
          'destination_airport': 'Hedef havaalanı',
          'departure_trainstation': 'Kalkış istasyonu',
          'destination_trainstation': 'Hedef tren istasyonu',
          'departure_car': 'Kalkış yeri',
          'destination_car': 'Hedef Yer',
          'from': 'itibaren',
          'to': 'için'
        },
        'weekdays': [
          'PZT ',
          'SAL',
          'CAR',
          'PER',
          'CUM',
          'CMT',
          'PAZ'
        ],
        'months': [
          "Ocak",
          "Şubat",
          "Mart",
          "Nisan",
          "Mayıs",
          "Haziran",
          "Temmuz",
          "Ağustos",
          "Eylül",
          "Ekim",
          "Kasım",
          "Aralık"
        ]
      },
      "reserve": {
        "title": "Tarihi (tarihleri) oluşturamıyor musunuz?",
        "text": "Ön kayıt oluşturun! Olası yeni tarihler hakkında bilgilendirileceksiniz.",
        "button": "On kayıt oluştur",
        "prebooked": {
          "text": "Bu eğitim ile ilgilendiğinize dair ön kayıt oluşturdunuz."
        }
      },
      'downloads': {
        'modalHeadline': 'İndirilenler',
        'sidebarLink': 'Belgeler',
      }
    },
    "course": {
      "type": {
        "0": "Çevrim içi eğitim",
        "1": "Çevrim içi eğitim",
        "2": "Çevrim içi eğitim",
        "3": "Çevrim içi eğitim",
        "4": "Çevrim içi eğitim",
        "5": "Çevrim içi eğitim",
        "6": "Yüz yüze eğitim",
        "7": "Web semineri",
        "8": "Proje",
        "undefined": "-"
      },
      "status": {
        "open": "Açık",
        'sent': 'Sent',
        "passed": "Başarılı",
        'sent': 'Gönderilmiş',
        "failed": "Başarısız",
        'participated': 'Participated',
        'notParticipated': 'Not participated',
        "expired": "Süre doldu",
        "soonExpired": "Süre yakında doluyor",
        "booked": "Rezervasyon yapıldı",
        "started": "Düzenleniyor",
        "upcoming": "Beklemede",
        "running": "Devam ediyor",
        "completed": "Tamamlandı",
        "notyet": "Henüz mevcut değil"
      },
      "notification": {
        "success1": "Eğitimi ",
        "success2": " geçtiniz!"
      },
      'warning': {
        'headline': 'Dikkat!',
        'body': {
          '1': 'Bu eğitim için son seferden farklı bir dil seçtiniz.',
          '2': 'Devam edip \'Eğitimi başlat\'a tıklarsanız ilerleme sıfırlanır.',
        },
        'buttons': {
          'start': 'Eğitimi başlat',
          'startPrevious': 'Önceki dili seçin',
        }
      },
      "statusLabel": {
        "training": "Eğitim",
        'attendance': 'Attendance',
        "exam": "Sınav",
        "survey": "Anket",
        "efficacyControl": "Etkinlik kontrolü"
      },
      "start": {
        "survey": "Anketi başlat",
        "exam": "Sınavı başlat",
        "efficacyControl": "Etkinlik kontrolünü başlat"
      },
      "prefix": {
        "category": "Kategori",
        "state": "Durum",
        "type": "Eğitim türü"
      },
      "noAssigned": "Bir eğitim atamanız bulunmuyor.",
      "noSolved": "Henüz bir eğitim tamamlamadınız. \"Eğitimlerim\" bölümünden şimdi ilk öğrenme ünitenizi başlatın!",
      "noAssignedBookable": "Rezerve edilebilecek bir eğitim atamanız bulunmuyor.",
      "noResult": "Kriterlere uygun bir eğitim bulunamadı.",
      "loadingFailed": "Talep edilen veri setleri, bilinmeyen bir hatadan dolayı yüklenemedi!",
      "noResultInCategory": "Kategoriye henüz bir eğitim ataması gerçekleşmedi.",
      "meeting": {
        "noMeeting": "tarih yok",
        "multipleMeetings": "çeş. tarihler"
      },
      "project": {
        "courses": "Eğitimler"
      }
    },
    "login": {
      "invalidUsername": "Oturum geçersiz. Kullanıcı adı yanlış!",
      "invalidUser": "Oturum geçersiz. Kullanıcı adı / şifre yanlış!",
      "invalidPassword": "Oturum geçersiz. Şifreyi doğru girmek için #0 denemeniz kaldı.",
      "accountBlocked": "Hesabınız bloke edildi. Lütfen sorumlu çalışana başvurun.",
      "error": "Kullanıcı adı ve/veya şifre yanlış!",
      "empty": "Lütfen tüm alanları doldurun.",
      "back": "Oturum açma bölümüne geri git",
      "description": "Giriş bilgilerinin tekrar gönderilmesi için lütfen kullanıcı adınızı veya e-posta adresinizi girin.",
      "password-reset": {
        "description": "Lütfen kullanıcı hesabınız için yeni bir şifre seçin.",
        "success": "Şifreniz başarıyla değiştirildi.",
        "error": "Girilen şifreler aynı değil."
      },
      "placeholder": " Kullanıcı adı / e-posta adresi",
      "button": "Giriş bilgilerini talep et",
      "success": "Talep başarıyla gönderildi.",
      "errorForgot": "Belirtilen kriterlere uygun bir kullanıcı bulunamadı.",
      "errorForgotSecurityQuestion": "Cevap maalesef doğru değil!"
    },
    "registration": {
      "description": "Henüz giriş bilgileriniz yoksa, şirketinizin kampanya kodu üzerinden kolayca kaydolabilirsiniz. Ardından size derhal bir e-posta gönderilecektir.",
      "actionCodeError": "Girilen kayıt kodu geçersiz",
      "actionCodeLimitExceeded": "Girilen kampanya kodu için mümkün olan maksimum kayıt sayısı kullanıldı.",
      "registerDescription": "Lütfen aşağıdaki formu doldurun. Giriş bilgileriniz size birkaç dakika içerisinde e-posta yoluyla gönderilecektir.",
      "title": "Akademi kaydınız",
      "required": "Zorunlu alan*",
      "emailError": "Girdiğiniz e-posta adresi zaten kullanılıyor.",
      "emailErrorNotAllowed": "Bu e-posta adresi ile kayıt yapılamaz.",
      "send": "Gönder",
      "completeMessage": "Akademi kaydınız başarıyla tamamlandı!"
    },
    "dataProtection": {
      "accept": "Onayla",
      "acceptText": "Evet, Kullanım Koşullarını / Veri Gizliliğini onaylıyorum"
    },
    "securityQuestion": {
      "title": "Güvenlik sorgusu",
      "chooseQuestion": "Lütfen seçin",
      "questionSelection": "Lütfen bir soru seçin",
      "yourAnswer": "Lütfen cevabınızı girin",
      "description": "Lütfen güvenlik sorgusunu yanıtlayın ve kullanıcı hesabınız için yeni bir şifre seçin."
    },
    "faq": {
      "title": "Sıkça sorulan sorular"
    }
  },
  "sidebar": {
    "all": "Tümü",
    "categories": "KATEGORİLER",
    "status": "DURUM",
    "courseType": "EĞİTİM TÜRÜ",
    "topics": "KONU ALANLARI"
  },
  "placeholder": {
    "username": "Kullanıcı adı",
    "password": "Şifre",
    "actionCode": "Kampanya kodu"
  },
  "button": {
    "login": "KAYDOL",
    "login-sso": "ACTIVE-DIRECTORY OTURUM AÇMA",
    "send": "GÖNDER",
    "start_course": "BAŞLA",
    "changePassword": "Şifreyi değiştir",
    "reset": "Sıfırla",
    "save": "Kaydet",
    "request": "Talep",
    "storno": "İptal et",
    "canceled": "Tarih iptal edildi",
    "close": "Kapat",
    "booking": "Rezervasyon yap",
    "waitlist": "Bekleme listesi",
    "start_webinar": "Web seminerini başlat",
    "open_webinar": "Web seminerini aç",
    'cancel': 'İptal etmek',
    'confirm': 'Onaylamak'
  },
  "bookingState": {
    "0": "TALEP EDİLDİ",
    "1": "REZERVASYON YAPILDI",
    "2": "BEKLEME LİSTESİ",
    "3": "İPTAL EDİLDİ",
    "4": "İPTAL EDİLDİ",
    "5": "REDDEDİLDİ",
    "6": "ÖN KAYIT OLUŞTURULDU",
    "onlineTraining": {
      "0": "TALEP EDİLDİ",
      "1": "REZERVASYON YAPILDI",
      "2": "REDDEDİLDİ"
    }
  },
  "foodPreference": {
    "none": "Yok",
    "lactoseIntolerance": "Laktoz intoleransı",
    "vegetarian": "Vejetaryen",
    "vegan": "Vegan",
    "nutAllergy": "Fındık alerjisi",
    "diabetic": "Diyabet",
    "glutenFree": "Glütensiz",
    "kosher": "Koşer",
    "halal": "Helal",
    "other": "Diğer"
  },
  "notifications": {
    "error": "Bir sorun oluştu!",
    "errors": {
      "missingInput": "Lütfen alanları doldurun",
      "imageLoad": "Talep edilen görsel yüklenemedi"
    },
    "success": {
      "book": {
        "meeting": "Yüz yüze eğitimi rezervasyonunuz başarılı.",
        "webinar": "Web semineri rezervasyonunuz başarılı.",
        "course": "Eğitim rezervasyonunuz başarılı.",
        "project": "Proje rezervasyonunuz başarılı."
      },
      "request": {
        "meeting": "Yüz yüze eğitim talebiniz başarılı.",
        "webinar": "Web semineri talebiniz başarılı.",
        "course": "Eğitim talebiniz başarılı.",
        "project": "Proje talebiniz başarılı."
      },
      "profile": {
        "updateSuccess": "Profiliniz başarıyla güncellendi!",
        "updatePasswordSuccess": "Şifreniz başarıyla güncellendi!"
      },
      "securityQuestion": {
        "questionSave": "Güvenlik sorgusu kaydedildi!"
      }
    }
  },
  "webservice": {
    "error": {
      "duplicate": "Bu rezervasyon zaten mevcut!",
      "noaccess": "Yetkisiz erişim.",
      "notimplementet": "Bu fonksiyon şu anda kullanılamıyor."
    },
    "success": {
      "booking": "Rezervasyonunuz başarılı.",
      "request": "Talebiniz başarılı.",
      "cancel": "İptal işleminiz başarılı. ",
      "waitlist": {
        "add": "Bekleme listesine alındınız"
      },
      "reservelist": {
        "add": "Ön kayıt listesine alındınız"
      }
    },
    "courseCalendar": {
      "noDate": "Süresiz"
    }
  },
  "trainingmanager": {
    "headline": "İleri eğitim talebi",
    "headlinerequest": "Başvuru oluştur",
    "bottonrequest": "Başvuru oluştur",
    "buttonmanage": "Başvuruları yönet",
    "requestform": {
      "requester": "Başvuru sahibi:",
      "date": "Tarih:",
      "tab01": "Talep oluştur",
      "tab02": "Grup/üçüncü kişiler için talep",
      "tab01headline01": "Katılımcının adı:",
      "tab01headline02": "İleri eğitim konusu:",
      "tab01headline04": "Katılımcıların adı:",
      "tab01headline03": "Kurs detayları:",
      "tab01label01": "Hitap:",
      "tab01label02": "Adı:",
      "tab01label03": "Soyadı:",
      "tab01label04": "Bay",
      "tab01label05": "Bayan",
      "tab01label06": "Kategori:",
      "tab01label07": "Unvan*:",
      "tab01label08": "Kısa açıklama:",
      "tab01label09": "Diğer:",
      "tab01label09O": "Diğer",
      "tab01label10": "Ödeme noktası:",
      "tab01label11": "Tarih (başlangıç/bitiş):*",
      "tab01label12": "Kurs numarası:",
      "tab01label13": "Fiyat:",
      "tab01label14": "Sunucu:",
      "tab01label15": "Ek:",
      "tab01label16": "Dosya seç",
      "tab01label17": "Dosya seçilmedi...",
      "tab01label18": "Başvuruyu gönder",
      "tab01label19": "Başka kişileri ekle",
      "tab01label20": "İptal et",
      "olheadline": "Katılımcı ekle",
      "olheadline1": "Katılımcının adı",
      "olbodytext": "Aşağıda diğer eğitim katılımcılarının isimlerini seçin.",
      "olerror": "Bir katılımcı bulunamadı. Lütfen arama parametrelerinizi değiştirin."
    },
    "manage": {
      "headline": "Başvuruları yönet",
      "headlineInfo": "Çalışan başvuruları",
      "bodytextInfo": "Aşağıda, işleme alınmasını rica ettiğimiz bekleyen tüm ileri eğitim başvurularını bulabilirsiniz.",
      "newRequest": "Kendi başvurunu gönder",
      "tableHeadline01": "Giriş",
      "tableHeadline02": "Başvuru sahibi",
      "tableHeadline03": "Kurs no.",
      "tableHeadline04": "Başlık",
      "tableHeadline05": "Tarih",
      "tableHeadline06": "Fiyat",
      "tableMark": "Tümünü işaretle",
      "buttonapprove": "Onayla",
      "buttondecline": "Onaylama",
      "modal": {
        "refuse": {
          "headline": "Mesaj - Talebi reddet",
          "bodytext": "",
          "placeholder": "Lütfen bir metin girin...",
          "checkbox": "Talebi mesaj olmadan reddet",
          "button": "Gönder",
          "approval": "Onaylandı",
          "refuse": "Onaylanmadı"
        }
      }
    }
  },
  'projectBookingModal': {
    'headlines': {
      'chooseMeeting': 'tarih seç',
      'summaryMeeting': 'Randevuya genel bakış',
      'summary': 'özet',
      'online': 'genel bakış',
      'mixed': 'genel bakış',
      'information': 'rezervasyon seçenekleri'
    },
    'description': 'Lütfen göndermeden önce seçtiğiniz rezervasyon bilgilerini doğrulayın.'
  },
  'multiModal': {
    'buttons': {
      'cancel': 'iptal',
      'next': 'Devam et',
      'back': 'Dönüş',
      'send': 'Göndermek'
    }
  }
}
