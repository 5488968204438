// Niederländisch

module.exports = {
  "main_navigation": {
    "login": "Login",
    "registration": "Registratie",
    "courses": "Mijn trainingen",
    "coursesDetail": "Trainingdetails",
    "catalog": "Catalogus",
    "learningProfile": "Leerprofiel",
    "contact": "Contact",
    "profile": "Profiel",
    "pwLost": "Login-gegevens vergeten?",
    "about": "Colofon",
    "terms_and_conditions": "Gebruiksvoorwaarden/gegevensbescherming",
    "logout": "Afmelden",
    "faq": "FAQ",
    "trainingmanager": "Verzoeken",
    "trainingmanagerrequest": "Aanvraag",
    "trainingmanagermanage": "Manage",
    "trainingmanagermanagedetail": "Detail"
  },
  "general": {
    'offline': {
      'headline': 'Je bent offline!',
      'text': 'Deze app heeft een actieve internetverbinding nodig om goed te werken.',
    },
    "year": "Jaar",
    "month": "Maand",
    "noResult": "Er werden geen resultaten gevonden.",
    "months": {
      "10": "Oktober",
      "11": "November",
      "12": "December",
      "01": "Januari",
      "02": "Februari",
      "03": "Maart",
      "04": "April",
      "05": "Mei",
      "06": "Juni",
      "07": "Juli",
      "08": "Augustus",
      "09": "September"
    },
    "category": "Categorie",
    "calendar": "Kalender",
    "search": "Zoeken",
    "searchError": "Voer ten minste drie tekens in.",
    "showLess": "Minder tonen",
    "showMore": "Meer tonen",
    'pagination': {
      'next': 'Verder',
      'prev': 'Terug',
      'separator': 'van',
      'pageSize': 'Artikelen per pagina'
    }
  },
  "maintenance": {
    "title": "Onderhoudswerkzaamheden",
    "text": "Vanwege onderhoudswerkzaamheden staat de academie op dit moment niet ter beschikking. Probeer het later nog een keer."
  },
  'booking': {
    'cancel': {
      'title': 'Cancel booking',
      'confirm': 'are you sure you want to cancel this booking?'
    }
  },
  "pages": {
    "notFound": {
      "headline": "Het spijt ons!",
      "text1": "De gevraagde pagina is niet gevonden",
      "text2": "Gebruik de terug-knop van uw browser of ga naar de startpagina.",
      "button": "Terug naar de startpagina"
    },
    "courseCalendar": {
      "expired": "Verlopen",
      "soonExpired": "Verloopt binnenkort",
      'expiredMeetings': 'Verleden data'
    },
    "profile": {
      "userdata": "Gebruikersgegevens",
      "password": "Wachtwoord",
      "genderLab": "Aanhef",
      "gender": {
        "men": "Dhr.",
        "woman": "Mevr.",
        "diverse": "Div.",
        "unknown": "Geen informatie"
      },
      "firstname": "Voornaam",
      "lastname": "Achternaam",
      "email": "E-mail",
      "strasse": "Straat/huisnummer",
      "city": "Postcode/woonplaats",
      "phone": "Telefoon",
      "fax": "Fax",
      "position": "Positie",
      "department": "Afdeling",
      "required": "Verplichte veld",
      "oldPassword": "Oud wachtwoord",
      "newPassword": "Nieuw wachtwoord",
      "newPasswordRep": "Herhaling",
      "company": "Bedrijf",
      "language": "Taal"
    },
    "catalog": {
      "back": "Terug",
      "bookingDialog": {
        "notice": "Vermeld hier de reden voor uw trainingsaanvraag"
      }
    },
    "contact": {
      "info": "Vermeld uw aanvraag alstublieft in het mededelingsveld. Als u zich beroept op een speciale training, vermeld deze dan hier: Zo kan uw mededeling direct naar de juiste afdeling worden gestuurd.",
      "trainingSelection": "Trainingskeuze",
      "yourMessage": "Uw mededeling",
      "success": "Uw mededeling werd met succes verstuurd!",
      "otherReason": "Algemene contactaanvraag",
      "otherReasonSubject": "Andere aanvraag",
      "chooseReason": "Alstublieft selecteren"
    },
    "learningProfile": {
      "title": "Leerprofiel",
      "unit": "Leereenheden",
      "points": "Totaal aantal punten",
      "history": "Leergeschiedenis",
      "restoreFilter": "Filter resetten",
      "certificate": "Certificaat",
      'downloadAll': 'Alle downloaden',
      "withoutCategory": "Zonder categorie",
      "tooltip01": "U heeft",
      "tooltip02": "van",
      "tooltip03": "leereenheden afgerond.",
      'gamification': {
        'badge':{
          'title': 'Prijzen',
          'congratulations': 'Hartelijk gefeliciteerd!',
          'achieved': 'U hebt een nieuwe onderscheiding gekregen!'
        }
      }
    },
    "courseDetail": {
      "title": "Trainingsdetails",
      'titleProject': 'Project details',
      "back": "Terug naar de lijst",
      "progress": "Voortgang",
      "expiry": "geldig tot",
      "classroomNo": "Trainingsnummer",
      "contactPerson": "Contactpersoon",
      "targetGroup": "Doelgroep",
      "bonusPoints": "Bonuspunten",
      "classroomContent": "Trainingsinhoud",
      "webinarLight": "Webinar informatie",
      "specials": "Bijzonderheden",
      "stornoConditions": "Annuleringsvoorwaarden",
      "meeting": "Data",
      'meetingBooking': 'Boek data',
      'meetingRequests': 'Aanvraag data',
      'freeDay': 'Free day',
      'freeDayInfo': 'Vrije dagen zijn boven niet vermeld',
      "speaker": "Trainer/referent",
      "location": "Trainingslocatie",
      "requested": "AANGEVRAAGD",
      "waitlist": "WACHTLIJST",
      "fullyBooked": "VOLGEBOEKT",
      "edit": "Bewerken",
      "webinarSpeaker": "Referent",
      "docsAndLinks": "Documenten en links",
      "links": "Links",
      "webinar": {
        "host": "Webinar openen",
        "state": {
          "ended": "Webinar is beëindigd",
          "beforeStart": "Webinar start op "
        }
      },
      "sidebar": {
        "price": "Prijs p.p.",
        "duration": "Leerduur",
        "to": "tot",
        "numberOfParticipants": "Aantal deelnemers",
        "status": "Boekingsstatus",
        'totalStatus': 'Algemene status',
        'contentStatus': 'Opleidingsstatus'
      },
      "bookingInformation": {
        "title": "Boekingsinformatie",
        "note": "Opmerking",
        "accounts": "Kostenplaats",
        "invoiceRecipient": "Factuurontvanger",
        "invoiceAddress": "Factuuradres",
        "food": "Verzorgingsvoorkeur",
        "arrival": "Aankomst",
        "arrivalType": {
          "none": "Geen informatie",
          "car": "Auto",
          "train": "Trein",
          "plane": "Vliegtuig"
        },
        "arrivalDate": "Aankomstdatum",
        "departure": "Vertrek",
        "departureDate": "Vertrekdatum",
        "flightNumber": "Vluchtnummer",
        "trainNumber": "Trein nummer",
        "railwayStation": "Station",
        'placeholder': {
          'departure_airport': 'Luchthaven van vertrek',
          'destination_airport': 'Luchthaven van bestemming',
          'departure_trainstation': 'Station van vertrek',
          'destination_trainstation': 'Station van bestemming',
          'departure_car': 'Plaats van vertrek',
          'destination_car': 'Bestemming Plaats',
          'from': 'van',
          'to': 'tot'
        },
        'weekdays': [
          'Ma',
          'Di',
          'Woe',
          'Do',
          'Vri',
          'Zat',
          'Zon'
        ],
        'months': [
          "Januari",
          "Februari",
          "Maart",
          "April",
          "Mei",
          "Juni",
          "Juli",
          "Augustus",
          "September",
          "Oktober",
          "November",
          "December",
        ]
      },
      "reserve": {
        "title": "U kunt niet op de termijn(en)?",
        "text": "Noteer uw interesse! U wordt dan geïnformeerd over mogelijke nieuwe ermijnen.",
        "button": "Noteren",
        "prebooked": {
          "text": "U heeft uw interesse aan deze training genoteerd."
        }
      },
      'downloads': {
        'modalHeadline': 'Downloads',
        'sidebarLink': 'Document(en)',
      }
    },
    "course": {
      "type": {
        "0": "Online training",
        "1": "Online training",
        "2": "Online training",
        "3": "Online training",
        "4": "Online training",
        "5": "Online training",
        "6": "Training ter plaatse",
        "7": "Webinar",
        "8": "Project",
        "undefined": "-"
      },
      "status": {
        "open": "Open",
        'sent': 'Sent',
        "passed": "Geslaagd",
        'sent': 'Verstuurd',
        "failed": "Niet geslaagd",
        'participated': 'Participated',
        'notParticipated': 'Not participated',
        "expired": "Verlopen",
        "soonExpired": "Verloopt binnenkort",
        "booked": "Geboekt",
        "started": "In behandeling",
        "upcoming": "Aanstaand",
        "running": "Lopend",
        "completed": "Voltooid"
      },
      "notification": {
        "success1": "U bent ",
        "success2": " geslaagd voor de training!"
      },
      'warning': {
        'headline': 'Attentie!',
        'body': {
          '1': 'U heeft voor deze training een andere taal gekozen dan de vorige keer.',
          '2': 'Als u doorgaat en op \'Start training\' klikt, wordt de voortgang gereset.',
        },
        'buttons': {
          'start': 'Start training',
          'startPrevious': 'Kies de vorige taal',
        }
      },
      "statusLabel": {
        "training": "Training",
        'content': 'Inhoud',
        'attendance': 'Attendance',
        "exam": "Test",
        "survey": "Enquête"
      },
      "start": {
        "survey": "Enquête starten",
        "exam": "Test starten"
      },
      "prefix": {
        "category": "Categorie",
        "state": "Status",
        "type": "Trainingstype"
      },
      "noAssigned": "Er zijn geen trainingen toegewezen.",
      "noSolved": "U heeft nog geen training voltooid. Start nu in het bereik 'Mijn trainingen' met uw eerste leereenheid!",
      "noAssignedBookable": "Er zijn geen te boeken trainingen toegewezen.",
      "noResult": "Er werden geen trainingen gevonden die voldoen aan de criteria.",
      "loadingFailed": "De aangevraagde datasets kunnen vanwege een onbekende fout niet worden geladen!",
      "noResultInCategory": "Er werd nog geen training toegevoegd aan de categorie.",
      "meeting": {
        "noMeeting": "geen termijn",
        "multipleMeetings": "div. data"
      },
      "project": {
        "courses": "Trainingen"
      }
    },
    "login": {
      "error": "Gebruikersnaam en/of wachtwoord is fout!",
      "empty": "Vul alstublieft alle velden in.",
      "back": "Terug naar de log-in",
      "description": "Vermeld alstublieft uw gebruikersnaam of uw E-mailadres voor het hernieuwd toesturen van de toegangsgegevens.",
      "password-reset": {
        "description": "Kies alstublieft een nieuw wachtwoord voor uw gebruikersaccount.",
        "success": "Uw wachtwoord werd succesvol veranderd.",
        "error": "De vermelde wachtwoorden zijn niet identiek."
      },
      "placeholder": " Gebruikersnaam / E-mailadres",
      "button": "Toegangsgegevens aanvragen",
      "success": "Aanvraag met succes verstuurd.",
      "errorForgot": "Er kon geen gebruiker worden gevonden, die voldoet aan de vermelde criteria.",
      "errorForgotSecurityQuestion": "Het antwoord is helaas niet juist!"
    },
    "registration": {
      "description": "Mocht u nog geen log-in gegevens bezitten, kunt u zich gewoon via de actiecode van uw bedrijf registreren. Vervolgens ontvangt u een E-mail.",
      "actionCodeError": "De vermelde registratiecode is niet geldig.",
      "actionCodeLimitExceeded": "Het maximaal aantal mogelijke registraties voor de vermelde actiecode is verbruikt.",
      "registerDescription": "Vul alstublieft het onderstaande formulier in. U ontvangt uw toegangsgegevens over enkele minuten per E-mail.",
      "title": "Uw Academie-registratie",
      "required": "Verplichte veld*",
      "emailError": "Het door u vermelde E-mailadres is reeds aangemeld.",
      "send": "Versturen",
      "completeMessage": "De registratie voor de Academie was succesvol!"
    },
    "dataProtection": {
      "accept": "Ga akkoord",
      "acceptText": "Ja, ik ga akkoord met de gebruiksvoorwaarden/gegevensbescherming"
    },
    "securityQuestion": {
      "title": "Veiligheidsvraag",
      "chooseQuestion": "Kies alstublieft",
      "questionSelection": "Kies alstublieft een vraag",
      "yourAnswer": "Vermeld alstublieft uw antwoord",
      "description": "Beantwoord alstublieft de veiligheidsvraag en kies een nieuw wachtwoord voor uw gebruikersaccount."
    },
    "faq": {
      "title": "Vaak gestelde vragen"
    }
  },
  "sidebar": {
    "all": "Alle",
    "categories": "CATEGORIEËN",
    "status": "STATUS",
    "courseType": "TRAININGSTYPE",
    "topics": "ONDERWERPEN"
  },
  "placeholder": {
    "username": "Gebruikersnaam",
    "password": "Wachtwoord",
    "actionCode": "Actiecode"
  },
  "button": {
    "login": "AANMELDEN",
    "login-sso": "ACTIVE-DIRECTORY LOG-IN",
    "send": "VERSTUREN",
    "start_course": "START",
    "changePassword": "Wachtwoord wijzigen",
    "reset": "Resetten",
    "save": "Opslaan",
    "request": "Aanvraag",
    "storno": "Annuleren",
    "close": "Sluiten",
    "booking": "Boeken",
    "waitlist": "Wachtlijst",
    "start_webinar": "Webinar starten",
    "open_webinar": "Webinar openen",
    'cancel': 'Annuleren',
    'confirm': 'Bevestigen'
  },
  "bookingState": {
    "0": "AANGEVRAAGD",
    "1": "GEBOEKT",
    "2": "WACHTLIJST",
    "3": "GEANNULEERD",
    "4": "GEANNULEERD",
    "5": "GEWEIGERD",
    "6": "GENOTEERD",
    "onlineTraining": {
      "0": "AANGEVRAAGD",
      "1": "GEBOEKT",
      "2": "GEWEIGERD"
    }
  },
  "foodPreference": {
    "none": "Geen",
    "lactoseIntolerance": "Lactose-onverdraagzaamheid",
    "vegetarian": "Vegetarisch",
    "vegan": "Veganistisch",
    "nutAllergy": "Notenallergie",
    "diabetic": "Diabetes",
    "glutenFree": "Gluteenvrij",
    "kosher": "Kosher",
    "halal": "Halal",
    "other": "Overige"
  },
  "notifications": {
    "error": "Er is iets fout gegaan!",
    "errors": {
      "missingInput": "Vul alstublieft de velden in",
      "imageLoad": "Het aangevraagde beeld kon niet worden geladen"
    },
    "success": {
      "book": {
        "meeting": "U heeft de training ter plaatse met succes geboekt.",
        "webinar": "U heeft het webinar met succes geboekt.",
        "course": "U heeft de training met succes geboekt.",
        "project": "U heeft het project met succes geboekt."
      },
      "request": {
        "meeting": "U heeft de training ter plaatse met succes aangevraagd.",
        "webinar": "U heeft het webinar met succes aangevraagd.",
        "course": "U heeft de training met succes aangevraagd.",
        "project": "U heeft het project met succes aangevraagd."
      },
      "profile": {
        "updateSuccess": "U heeft uw profiel succesvol geactualiseerd!",
        "updatePasswordSuccess": "U heeft uw wachtwoord succesvol geactualiseerd!"
      },
      "securityQuestion": {
        "questionSave": "Uw veiligheidsvraag werd opgeslagen!"
      }
    }
  },
  "webservice": {
    "error": {
      "duplicate": "De boeking bestaat reeds!",
      "noaccess": "Toegang niet toegestaan.",
      "notimplementet": "Functie staat momenteel niet ter beschikking."
    },
    "success": {
      "booking": "U heeft met succes geboekt.",
      "request": "U heeft met succes aangevraagd.",
      "cancel": "U heeft met succes geannuleerd. ",
      "waitlist": {
        "add": "U werd op de wachtlijst geplaatst"
      },
      "reservelist": {
        "add": "U werd op de notatielijst geplaatst"
      }
    },
    "courseCalendar": {
      "noDate": "Zonder termijn"
    }
  },
  "trainingmanager": {
    "headline": "Bijscholingsaanvraag",
    "headlinerequest": "Aanvraag plaatsen",
    "bottonrequest": "Aanvraag plaatsen",
    "buttonmanage": "Aanvragen beheren",
    "requestform": {
      "requester": "Aanvrager:",
      "date": "Datum:",
      "tab01": "Aanvraag plaatsen",
      "tab02": "Aanvraag voor groep/derden",
      "tab01headline01": "Naam van de deelnemer:",
      "tab01headline02": "Onderwerp van de bijscholing:",
      "tab01headline04": "Namen van de deelnemers:",
      "tab01headline03": "Cursusdetails:",
      "tab01label01": "Aanhef:",
      "tab01label02": "Voornaam:",
      "tab01label03": "Naam:",
      "tab01label04": "Dhr.",
      "tab01label05": "Mevr.",
      "tab01label06": "Categorie:",
      "tab01label07": "Titel*:",
      "tab01label08": "Korte beschrijving:",
      "tab01label09": "Overige:",
      "tab01label09O": "Overige",
      "tab01label10": "Kostenplaats:",
      "tab01label11": "Termijn (van/tot):*",
      "tab01label12": "Cursusnummer:",
      "tab01label13": "Prijs:",
      "tab01label14": "Aanbieder:",
      "tab01label15": "Bijlage:",
      "tab01label16": "Bestand selecteren",
      "tab01label17": "Geen bestand geselecteerd...",
      "tab01label18": "Aanvraag versturen",
      "tab01label19": "Verdere persoon toevoegen",
      "tab01label20": "Annuleren",
      "olheadline": "Deelnemer toevoegen",
      "olheadline1": "Naam van de deelnemer",
      "olbodytext": "Kies hierna de namen van andere trainingsdeelnemers uit.",
      "olerror": "Geen deelnemer gevonden. Verander alstublieft uw zoekparameters."
    },
    "manage": {
      "headline": "Aanvragen beheren",
      "headlineInfo": "Aanvragen van medewerkers",
      "bodytextInfo": "Hierna vindt u alle openstaande aanvragen voor bijscholingen met de vraag om bewerking.",
      "newRequest": "Eigen aanvraag versturen",
      "tableHeadline01": "Ingang",
      "tableHeadline02": "Aanvrager",
      "tableHeadline03": "Cursusnr.",
      "tableHeadline04": "Titel",
      "tableHeadline05": "Termijn",
      "tableHeadline06": "Prijs",
      "tableMark": "Alle markeren",
      "buttonapprove": "Toestemming verlenen",
      "buttondecline": "Vrijgave niet verlenen",
      "modal": {
        "refuse": {
          "headline": "Mededeling - aanvraag weigeren",
          "bodytext": "",
          "placeholder": "Vermeld alstublieft een tekst...",
          "checkbox": "Aanvraag zonder bericht weigeren",
          "button": "Versturen",
          "approval": "Toestemming verleend",
          "refuse": "Vrijgave niet verleend"
        }
      }
    }
  },
  'projectBookingModal': {
    'headlines': {
      'chooseMeeting': 'Kies een datum',
      'summaryMeeting': 'Overzicht afspraken',
      'summary': 'Samenvatting',
      'online': 'Overzicht',
      'mixed': 'Overzicht',
      'information': 'Reserveringsmogelijkheden'
    },
    'description': 'Controleer uw geselecteerde boekingsinformatie voordat u deze verzendt.'
  },
  'multiModal': {
    'buttons': {
      'cancel': 'Annuleren',
      'next': 'Verder',
      'back': 'Terug',
      'send': 'Dien in'
    }
  }
}
