// Griechisch

module.exports = {
  "main_navigation": {
    "login": "Σύνδεση",
    "registration": "Εγγραφή",
    "courses": "Οι εκπαιδεύσεις μου",
    "coursesDetail": "Λεπτομέρειες εκπαίδευσης",
    "catalog": "Κατάλογος",
    "learningProfile": "Μαθησιακό προφίλ",
    "contact": "Επικοινωνία",
    "profile": "Προφίλ",
    "pwLost": "\"Ξεχάσατε τα στοιχεία σύνδεσης",
    "about": "Στοιχεία έκδοσης",
    "terms_and_conditions": "Όροι χρήσης / Προστασία δεδομένων",
    "logout": "Αποσύνδεση",
    "faq": "FAQ (Συνήθεις ερωτήσεις)",
    "trainingmanager": "Αιτήματα",
    "trainingmanagerrequest": "Ερώτημα",
    "trainingmanagermanage": "Διαχείριση",
    "trainingmanagermanagedetail": "Λεπτομέρεια"
  },
  "general": {
    'offline': {
      'headline': 'Είστε εκτός σύνδεσης!',
      'text': 'Αυτή η εφαρμογή χρειάζεται ενεργή σύνδεση στο διαδίκτυο για να λειτουργήσει σωστά.',
    },
    "year": "Έτος",
    "month": "Μήνας",
    "noResult": "Δεν βρέθηκε αποτέλεσμα.",
    "months": {
      "10": "Οκτώβριος",
      "11": "Νοέμβριος",
      "12": "Δεκέμβριος",
      "01": "Ιανουάριος",
      "02": "Φεβρουάριος",
      "03": "Μάρτιος",
      "04": "Απρίλιος",
      "05": "Μάιος",
      "06": "Ιούνιος",
      "07": "Ιούλιος",
      "08": "Αύγουστος",
      "09": "Σεπτέμβριος"
    },
    "category": "Κατηγορία",
    "calendar": "Ημερολόγιο",
    "search": "Αναζήτηση",
    "searchError": "Εισάγετε τουλάχιστον τρεις χαρακτήρες.",
    "showLess": "Εμφάνιση λιγότερων",
    "showMore": "Εμφάνιση περισσότερων",
    'pagination': {
      'next': 'Επόμενο',
      'prev': 'Προηγούμενο',
      'separator': 'από',
      'pageSize': 'Στοιχεία ανά σελίδα',
    }
  },
  "maintenance": {
    "title": "Εργασίες συντήρησης",
    "text": "Η ακαδημία προς το παρόν δεν είναι διαθέσιμη λόγω εργασιών συντήρησης. Προσπαθήστε πάλι αργότερα."
  },
  "password": {
    "requirements": {
      "title": "Κατευθυντήριες γραμμές για τον κωδικό πρόσβασης",
      "minimalLength": "Το μήκος πρέπει να είναι τουλάχιστον #0.",
      "mandantName": "\"Δεν επιτρέπεται η χρήση του όρου \"\"#0\"\".\"",
      "names": "Ο κωδικός πρόσβασης δεν επιτρέπεται να είναι ίδιο με το όνομα ή το επώνυμο.",
      "userName": "Στον κωδικό πρόσβασης δεν πρέπει να περιλαμβάνονται τα ονόματά σας χρήστη.",
      "userNameEqual": "Στον κωδικό πρόσβασης δεν πρέπει να περιλαμβάνονται τα ονόματά σας χρήστη.",
      "bigLetters": "Πρέπει να υπάρχει/υπάρχουν τουλάχιστον #0 κεφαλαίος/-οι χαρακτήρας/-ες.",
      "smallLetters": "Πρέπει να υπάρχει/υπάρχουν τουλάχιστον #0 πεζός/-οί χαρακτήρας/-ες.",
      "digits": "Πρέπει να υπάρχει/υπάρχουν τουλάχιστον #0 αριθμός/-οί.",
      "sameLetters": "Δεν πρέπει να επαναλαμβάνεται διαδοχικά ο ίδιος χαρακτήρας πάνω από #0 φορές.",
      "oldPassword": "Ο νέος κωδικός πρόσβασης δεν επιτρέπεται να είναι ίδιος με τον παλιό.",
      "birthday": "Ο κωδικός πρόσβασης δεν πρέπει να αντιστοιχεί στην ημερομηνία γέννησής σας.",
      "minSymbolsPolicy": "Πρέπει να υπάρχει/υπάρχουν τουλάχιστον #0 ειδικός/-οί χαρακτήρας/-ες.",
      "minPasswordLength": "Ο κωδικός πρόσβασης πρέπει να έχει τουλάχιστον #0 χαρακτήρες.",
      "maxPasswordAttempts": "Έχετε ακόμη #0 υπολειπόμενες προσπάθειες να καταχωρίσετε σωστά τον κωδικό πρόσβασης."
    }
  },
  'booking': {
    'cancel': {
      'title': 'Cancel booking',
      'confirm': 'are you sure you want to cancel this booking?'
    }
  },
  "pages": {
    "notFound": {
      "headline": "Λυπούμαστε!",
      "text1": "Η ζητηθείσα σελίδα δεν βρέθηκε",
      "text2": "Χρησιμοποιήστε το πλήκτρο Επιστροφή του προγράμματος περιήγησής σας ή μεταβείτε στην αρχική σελίδα.",
      "button": "Επιστροφή στην αρχική σελίδα"
    },
    "courseCalendar": {
      "expired": "Έληξε",
      "soonExpired": "Λήγει προσεχώς",
      "expiredMeetings": "Προηγούμενες ημερομηνίες"
    },
    "profile": {
      "userdata": "Στοιχεία χρήστη",
      "password": "Κωδικός πρόσβασης",
      "genderLab": "Προσφώνηση",
      "gender": {
        "men": "Κύριε",
        "woman": "Κυρία",
        "diverse": "Άλλο",
        "unknown": "Δεν αναφέρεται"
      },
      "firstname": "Όνομα",
      "lastname": "Επώνυμο",
      "email": "Email",
      "strasse": "Οδός / Αρ.",
      "city": "ΤΚ / Τόπος",
      "phone": "Τηλέφωνο",
      "fax": "Φαξ",
      "position": "Θέση",
      "department": "Τμήμα",
      "required": "Υποχρεωτικό πεδίο",
      "oldPassword": "Παλιός κωδικός πρόσβασης",
      "newPassword": "Νέος κωδικός πρόσβασης",
      "newPasswordRep": "Επανάληψη",
      "company": "Επιχείρηση",
      "language": "Γλώσσα"
    },
    "catalog": {
      "back": "Επιστροφή",
      "bookingDialog": {
        "notice": "Καταχωρίστε εδώ την αιτιολόγησή σας για το αίτημά σας για εκπαίδευση"
      }
    },
    "contact": {
      "info": "Καταχωρίστε το ερώτημά σας στο πεδίο μηνύματος. Εάν αναφέρεστε σε συγκεκριμένη εκπαίδευση",
      "trainingSelection": "Επιλογή εκπαίδευσης",
      "yourMessage": "Το μήνυμά σας",
      "success": "Το μήνυμά σας εστάλη με επιτυχία!",
      "otherReason": "Γενικό ερώτημα επικοινωνίας",
      "otherReasonSubject": "Άλλο ερώτημα",
      "chooseReason": "Παρακαλώ"
    },
    "learningProfile": {
      "title": "Μαθησιακό προφίλ",
      "unit": "Διδακτικές ενότητες",
      "points": "Συνολική βαθμολογία",
      "history": "Μαθησιακό ιστορικό",
      "restoreFilter": "Επαναφορά φίλτρου",
      "certificate": "Πιστοποιητικό",
      'downloadAll': 'Λήψη όλων',
      "withoutCategory": "Χωρίς κατηγορία",
      "tooltip01": "Ολοκληρώσατε με επιτυχία",
      "tooltip02": "από",
      "tooltip03": "διδακτικές ενότητες.",
      "gamification": {
        "badge": {
          "title": "Διακρίσεις",
          "congratulations": "Θερμά συγχαρητήρια!",
          "achieved": "Λάβατε μία νέα διάκριση!"
        }
      }
    },
    "courseDetail": {
      "title": "Λεπτομέρειες εκπαίδευσης",
      'titleProject': 'Λεπτομέρειες έργου',
      "back": "Επιστροφή στη λίστα",
      "progress": "Πρόοδος",
      "expiry": "ισχύει έως",
      "classroomNo": "Αριθμός εκπαίδευσης",
      "contactPerson": "Αρμόδιος επικοινωνίας",
      "targetGroup": "Ομάδα-στόχος",
      "bonusPoints": "Βαθμοί μπόνους",
      "classroomContent": "Περιεχόμενο εκπαίδευσης",
      "webinarLight": "Πληροφορίες διαδικτυακού σεμιναρίου",
      "specials": "Ιδιαιτερότητες",
      "stornoConditions": "Όροι ακύρωσης",
      "meeting": "Ημερομηνίες",
      'meetingBooking': 'Κάντε κράτηση ημερομηνιών',
      'meetingRequests': 'Ημερομηνίες αίτησης',
      'freeDay': 'Free day',
      'freeDayInfo': 'Οι ελεύθερες ημέρες δεν αναφέρονται παραπάνω\n',
      "speaker": "Εκπαιδευτής / Παρουσιαστής",
      "location": "Τόπος εκπαίδευσης",
      "requested": "ΕΧΕΙ ΤΕΘΕΙ ΣΧΕΤΙΚΟ ΑΙΤΗΜΑ",
      "waitlist": "ΛΙΣΤΑ ΑΝΑΜΟΝΗΣ",
      "fullyBooked": "ΠΛΗΡΗΣ",
      "edit": "Επεξεργασία",
      "webinarSpeaker": "Παρουσιαστής",
      "docsAndLinks": "Έγγραφα και σύνδεσμοι",
      "links": "Σύνδεσμοι",
      "webinar": {
        "host": "Κήρυξη έναρξης διαδικτυακού σεμιναρίου",
        "state": {
          "ended": "Το διαδικτυακό σεμινάριο τελείωσε",
          "beforeStart": "Το διαδικτυακό σεμινάριο αρχίζει την "
        }
      },
      "sidebar": {
        "price": "Τιμή ανά άτομο",
        "duration": "Διάρκεια μάθησης",
        "to": "έως",
        "numberOfParticipants": "Αριθμός συμμετεχόντων",
        "status": "Κατάσταση κράτησης",
        'totalStatus': 'Συνολική κατάσταση',
        'contentStatus': 'Κατάσταση κατάρτισης'
      },
      "bookingInformation": {
        "title": "Πληροφορίες κράτησης",
        "note": "Παρατήρηση",
        "accounts": "Κέντρο κόστους",
        "invoiceRecipient": "Παραλήπτης τιμολογίου",
        "invoiceAddress": "Διεύθυνση τιμολόγησης",
        "food": "Διατροφική προτίμηση",
        "arrival": "Τρόπος μετάβασης",
        "arrivalType": {
          "none": "Δεν αναφέρεται",
          "car": "Αυτοκίνητο",
          "train": "Τρένο",
          "plane": "Αεροπλάνο"
        },
        "arrivalDate": "Ημερομηνία άφιξης",
        "departure": "Αναχώρηση",
        "departureDate": "Ημερομηνία αναχώρησης",
        "flightNumber": "Αριθμός πτήσης",
        "trainNumber": "Αριθμός τρένου",
        "railwayStation": "Σταθμός",
        'placeholder': {
          'departure_airport': 'Αεροδρόμιο αναχώρησης',
          'destination_airport': 'Αεροδρόμιο προορισμού',
          'departure_trainstation': 'Σταθμός αναχώρησης',
          'destination_trainstation': 'Σταθμός προορισμού',
          'departure_car': 'Τόπος αναχώρησης',
          'destination_car': 'Τόπος προορισμού',
          'from': 'από το',
          'to': 'στο'
        },
        'weekdays': [
          'Mon',
          'Tue',
          'Wed',
          'Thu',
          'Fri',
          'Sat',
          'Sun'
        ],
        'months': [
          'Ιανουάριος',
          'Φεβρουάριος',
          'Μάρτιος',
          'Απρίλιος',
          'Μάιος',
          'Ιούνιος',
          'Ιούλιος',
          'Αύγουστος',
          'Σεπτέμβριος',
          'Οκτώβριος',
          'Νοέμβριος',
          'Δεκέμβριος'
        ]
      },
      "reserve": {
        "title": "\"Δεν μπορείτε να συμμετέχετε κατά την/τις εν λόγω ημερομηνία/-ες",
        "text": "Δηλώστε εκ των προτέρων το ενδιαφέρον σας! Στη συνέχεια θα ενημερωθείτε για πιθανές νέες ημερομηνίες.",
        "button": "Προκαταρκτική εκδήλωση ενδιαφέροντος",
        "prebooked": {
          "text": "Καταχωρήθηκε η προκαταρκτική εκδήλωση του ενδιαφέροντός σας για αυτήν την εκπαίδευση."
        }
      },
      'downloads': {
        'modalHeadline': 'Λήψεις',
        'sidebarLink': 'Έγγραφο(α)',
      }
    },
    "course": {
      "type": {
        "0": "Online εκπαίδευση",
        "1": "Online εκπαίδευση",
        "2": "Online εκπαίδευση",
        "3": "Online εκπαίδευση",
        "4": "Online εκπαίδευση",
        "5": "Online εκπαίδευση",
        "6": "Εκπαίδευση με φυσική παρουσία",
        "7": "Διαδικτυακό σεμινάριο",
        "8": "Project",
        "undefined": "-"
      },
      "status": {
        "open": "Ανοικτή/-ό",
        'sent': 'Sent',
        "passed": "Ολοκληρώθηκε με επιτυχία",
        'sent': 'Αποστολή',
        "failed": "Δεν ολοκληρώθηκε με επιτυχία",
        'participated': 'Participated',
        'notParticipated': 'Not participated',
        "expired": "Έληξε",
        "soonExpired": "Λήγει προσεχώς",
        "booked": "Κρατήθηκε",
        "started": "Σε επεξεργασία",
        "upcoming": "Επίκειται",
        "running": "Σε εξέλιξη",
        "completed": "Ολοκληρώθηκε",
        "notyet": "Μη διαθέσιμη/-ο ακόμη"
      },
      "notification": {
        "success1": "Ολοκληρώσατε με επιτυχία την εκπαίδευση ",
        "success2": " !"
      },
      'warning': {
        'headline': 'Προσοχή!',
        'body': {
          '1': 'Έχετε επιλέξει διαφορετική γλώσσα από την προηγούμενη φορά για αυτή την εκπαίδευση.',
          '2': 'Εάν προχωρήσετε και κάνετε κλικ στο \'Έναρξη εκπαίδευσης\', η πρόοδος μηδενίζεται.',
        },
        'buttons': {
          'start': 'Έναρξη εκπαίδευσης',
          'startPrevious': 'Επιλογή προηγούμενης γλώσσας',
        }
      },
      "statusLabel": {
        "training": "Εκπαίδευση",
        'content': 'Content',
        'attendance': 'Attendance',
        "exam": "Εξέταση",
        "survey": "Έρευνα",
        "efficacyControl": "Έλεγχος αποτελ/τητας"
      },
      "start": {
        "survey": "Έναρξη έρευνας",
        "exam": "Έναρξη εξέτασης",
        "efficacyControl": "Έναρξη ελέγχου αποτελεσματικότητας"
      },
      "prefix": {
        "category": "Κατηγορία",
        "state": "Κατάσταση",
        "type": "Τύπος εκπαίδευσης"
      },
      "noAssigned": "Δεν σας έχουν αντιστοιχιστεί εκπαιδεύσεις.",
      "noSolved": "\"Δεν ολοκληρώσατε ακόμη εκπαιδεύσεις. Ξεκινήστε τώρα στην περιοχή \"\"Οι εκπαιδεύσεις μου\"\" την πρώτη σας διδακτική ενότητα!\"",
      "noAssignedBookable": "Δεν σας έχουν αντιστοιχιστεί εκπαιδεύσεις με δυνατότητα κράτησης.",
      "noResult": "Δεν βρέθηκαν εκπαιδεύσεις που να αντιστοιχούν στα κριτήρια.",
      "loadingFailed": "Δεν ήταν εφικτή η φόρτωση των ζητηθέντων συνόλων δεδομένων",
      "noResultInCategory": "Δεν αντιστοιχίστηκαν ακόμη εκπαιδεύσεις στην κατηγορία.",
      "meeting": {
        "noMeeting": "καμία ημερομηνία",
        "multipleMeetings": "διάφ. ημερομηνίες"
      },
      "project": {
        "courses": "Εκπαιδεύσεις"
      }
    },
    "login": {
      "invalidUser": "Μη έγκυρα στοιχεία σύνδεσης. Το όνομα χρήστη / ο κωδικός πρόσβασης είναι εσφαλμένο/-ος!",
      "invalidPassword": "Μη έγκυρα στοιχεία σύνδεσης. Έχετε ακόμη #0 υπολειπόμενες προσπάθειες να καταχωρίσετε σωστά τον κωδικό πρόσβασης.",
      "accountBlocked": "Ο λογαριασμός σας φράχθηκε. Αποταθείτε σε έναν αρμόδιο συνεργάρτη.",
      "error": "Το όνομα χρήστη και/ή ο κωδικός πρόσβασης είναι εσφαλμένο/-ος!",
      "empty": "Συμπληρώστε όλα τα πεδία.",
      "back": "Επιστροφή στη σύνδεση",
      "description": "Καταχωρίστε το όνομά σας χρήστη ή τη διεύθυνσή σας email για την εκ νέου αποστολή των στοιχείων πρόσβασης.",
      "password-reset": {
        "description": "Επιλέξτε έναν νέο κωδικό πρόσβασης για τον λογαριασμό σας χρήστη.",
        "success": "Ο κωδικός σας πρόσβασης άλλαξε με επιτυχία.",
        "error": "Οι κωδικοί πρόσβασης που αναφέρθηκαν δεν ταιριάζουν μεταξύ τους."
      },
      "placeholder": " Όνομα χρήστη / διεύθυνση email",
      "button": "Ζήτηση στοιχείων πρόσβασης",
      "success": "Το ερώτημα εστάλη με επιτυχία.",
      "errorForgot": "Δεν βρέθηκε χρήστης που να αντιστοιχεί στα κριτήρια που αναφέρθηκαν.",
      "errorForgotSecurityQuestion": "Η απάντησή σας είναι λανθασμένη!"
    },
    "registration": {
      "description": "Εάν δεν έχετε ακόμη στοιχεία σύνδεσης",
      "actionCodeError": "Ο κωδικός εγγραφής που καταχωρίστηκε δεν είναι έγκυρος",
      "actionCodeLimitExceeded": "Εξαντλήθηκε το μέγιστο πλήθος πιθανών εγγραφών για τον κωδικό προωθητικής ενέργειας που καταχωρίστηκε.",
      "registerDescription": "Συμπληρώστε την παρακάτω φόρμα. Τα στοιχεία πρόσβασής σας θα σας σταλούν σε λίγα λεπτά μέσω email.",
      "title": "Η εγγραφή σας στην ακαδημία",
      "required": "Υποχρεωτικό πεδίο*",
      "emailError": "Η διεύθυνση email που αναφέρατε χρησιμοποιείται ήδη.",
      "emailErrorNotAllowed": "Δεν είναι εφικτή η εγγραφή με αυτήν τη διεύθυνση email.",
      "send": "Αποστολή",
      "completeMessage": "Εγγραφήκατε με επιτυχία για την ακαδημία!"
    },
    "dataProtection": {
      "accept": "Συναίνεση",
      "acceptText": "Ναι"
    },
    "securityQuestion": {
      "title": "Ερώτηση ασφαλείας",
      "chooseQuestion": "Παρακαλώ",
      "questionSelection": "Επιλέξτε μια ερώτηση",
      "yourAnswer": "Καταχωρίστε την απάντησή σας",
      "description": "Απαντήστε στην ερώτηση ασφαλείας και επιλέξτε έναν νέο κωδικό πρόσβασης για τον λογαριασμό σας χρήστη."
    },
    "faq": {
      "title": "Συχνές ερωτήσεις (FAQ)"
    }
  },
  "sidebar": {
    "all": "Όλα",
    "categories": "ΚΑΤΗΓΟΡΙΕΣ",
    "status": "ΚΑΤΑΣΤΑΣΗ",
    "courseType": "ΤΥΠΟΣ ΕΚΠΑΙΔΕΥΣΗΣ",
    "topics": "ΘΕΜΑΤΙΚΟΙ ΤΟΜΕΙΣ"
  },
  "placeholder": {
    "username": "Όνομα χρήστη",
    "password": "Κωδικός πρόσβασης",
    "actionCode": "Κωδικός προωθητικής ενέργειας"
  },
  "button": {
    "login": "ΣΥΝΔΕΣΗ",
    "login-sso": "ACTIVE-DIRECTORY LOGIN",
    "send": "ΑΠΟΣΤΟΛΗ",
    "start_course": "ΕΝΑΡΞΗ",
    "changePassword": "Αλλαγή κωδικού πρόσβασης",
    "reset": "Επαναφορά",
    "save": "Αποθήκευση",
    "request": "Ερώτημα",
    "storno": "Ακύρωση",
    "close": "Κλείσιμο",
    "booking": "Κράτηση",
    "waitlist": "Λίστα αναμονής",
    "start_webinar": "Έναρξη διαδικτυακού σεμιναρίου",
    "open_webinar": "Άνοιγμα διαδικτυακού σεμιναρίου",
    'cancel': 'Ακύρωση',
    'confirm': 'Επιβεβαίωση'
  },
  "bookingState": {
    "0": "ΕΧΕΙ ΤΕΘΕΙ ΣΧΕΤΙΚΟ ΑΙΤΗΜΑ",
    "1": "ΚΡΑΤΗΘΗΚΕ",
    "2": "ΛΙΣΤΑ ΑΝΑΜΟΝΗΣ",
    "3": "ΑΚΥΡΩΘΗΚΕ",
    "4": "ΑΚΥΡΩΘΗΚΕ",
    "5": "ΑΠΟΡΡΙΦΘΗΚΕ",
    "6": "ΚΑΤΑΧΩΡΗΘΗΚΕ ΠΡΟΚΑΤΑΡΚΤΙΚΗ ΕΚΔΗΛΩΣΗ ΕΝΔΙΑΦΕΡΟΝΤΟΣ",
    "onlineTraining": {
      "0": "ΕΧΕΙ ΤΕΘΕΙ ΣΧΕΤΙΚΟ ΑΙΤΗΜΑ",
      "1": "ΚΡΑΤΗΘΗΚΕ",
      "2": "ΑΠΟΡΡΙΦΘΗΚΕ"
    }
  },
  "foodPreference": {
    "none": "Καμία",
    "lactoseIntolerance": "Δυσανεξία στη λακτόζη",
    "vegetarian": "Χορτοφάγος",
    "vegan": "Βήγκαν",
    "nutAllergy": "Αλλεργία σε ξηρούς καρπούς",
    "diabetic": "Σακχαρώδης διαβήτης",
    "glutenFree": "Χωρίς γλουτένη",
    "kosher": "Κόσερ",
    "halal": "Χαλάλ",
    "other": "Άλλες"
  },
  "notifications": {
    "error": "Κάτι δεν πήγε καλά!",
    "errors": {
      "missingInput": "Συμπληρώστε τα πεδία",
      "imageLoad": "Δεν εφικτή η φόρτωση της εικόνας που ζητήθηκε"
    },
    "success": {
      "book": {
        "meeting": "Πραγματοποιήσατε με επιτυχία μια κράτηση για την εκπαίδευση με φυσική παρουσία.",
        "webinar": "Πραγματοποιήσατε με επιτυχία μια κράτηση για το διαδικτυακό σεμινάριο.",
        "course": "Πραγματοποιήσατε με επιτυχία μια κράτηση για την εκπαίδευση.",
        "project": "Πραγματοποιήσατε με επιτυχία μια κράτηση για το project."
      },
      "request": {
        "meeting": "Θέσατε με επιτυχία ένα ερώτημα σχετικά με την εκπαίδευση με φυσική παρουσία.",
        "webinar": "Θέσατε με επιτυχία ένα ερώτημα για το διαδικτυακό σεμινάριο.",
        "course": "Θέσατε με επιτυχία ένα ερώτημα για την εκπαίδευση.",
        "project": "Θέσατε με επιτυχία ένα ερώτημα για το project."
      },
      "profile": {
        "updateSuccess": "Ενημερώσατε με επιτυχία το προφίλ σας.",
        "updatePasswordSuccess": "Ενημερώσατε με επιτυχία τον κωδικό πρόσβασής σας."
      },
      "securityQuestion": {
        "questionSave": "Η ερώτηση ασφαλείας σας αποθηκεύτηκε!"
      }
    }
  },
  "webservice": {
    "error": {
      "duplicate": "Η κράτηση υπάρχει ήδη!",
      "noaccess": "Άρνηση πρόσβασης!",
      "notimplementet": "Η λειτουργία προς το παρόν δεν είναι διαθέσιμη."
    },
    "success": {
      "booking": "Πραγματοποιήσατε με επιτυχία την κράτηση.",
      "request": "Θέσατε με επιτυχία ένα ερώτημα.",
      "cancel": "Πραγματοποιήσατε με επιτυχία την ακύρωση. ",
      "waitlist": {
        "add": "Μπήκατε στη λίστα αναμονής"
      },
      "reservelist": {
        "add": "Μπήκατε στη λίστα καταχώρισης προκαταρκτικής εκδήλωσης ενδιαφέροντος"
      }
    },
    "courseCalendar": {
      "noDate": "Χωρίς προθεσμία"
    }
  },
  "trainingmanager": {
    "headline": "Αίτημα για επιμόρφωση",
    "headlinerequest": "Υποβολή αίτησης",
    "bottonrequest": "Υποβολή αίτησης",
    "buttonmanage": "Διαχείριση αιτήσεων",
    "requestform": {
      "requester": "Αιτών:",
      "date": "Ημερομηνία:",
      "tab01": "Υποβολή αιτήματος",
      "tab02": "Αίτημα για ομάδα/τρίτους",
      "tab01headline01": "Όνομα του συμμετέχοντα:",
      "tab01headline02": "Θέμα της επιμόρφωσης:",
      "tab01headline04": "Όνομα των συμμετεχόντων:",
      "tab01headline03": "Λεπτομέρειες εκπαίδευσης:",
      "tab01label01": "Προσφώνηση:",
      "tab01label02": "Όνομα:",
      "tab01label03": "Επώνυμο:",
      "tab01label04": "Κύριε",
      "tab01label05": "Κυρία",
      "tab01label06": "Κατηγορία:",
      "tab01label07": "Τίτλος*:",
      "tab01label08": "Σύντομη περιγραφή:",
      "tab01label09": "Λοιπά:",
      "tab01label09O": "Λοιπά:",
      "tab01label10": "Κέντρο κόστους:",
      "tab01label11": "Ημερομηνία (από/έως):",
      "tab01label12": "Αριθμός εκπαίδευσης:",
      "tab01label13": "Τιμή:",
      "tab01label14": "Πάροχος:",
      "tab01label15": "Συνημμένο:",
      "tab01label16": "Επιλογή αρχείου",
      "tab01label17": "Δεν έχει επιλεγεί αρχείο...",
      "tab01label18": "Αποστολή αίτησης",
      "tab01label19": "Προσθήκη και άλλων προσώπων",
      "tab01label20": "Ακύρωση",
      "olheadline": "Προσθήκη συμμετέχοντα",
      "olheadline1": "Όνομα του συμμετέχοντα",
      "olbodytext": "Επιλέξτε παρακάτω τα ονόματα των άλλων συμμετεχόντων στην εκπαίδευση.",
      "olerror": "Δεν βρέθηκαν συμμετέχοντες. Αλλάξτε τις παραμέτρους σας αναζήτησης."
    },
    "manage": {
      "headline": "Διαχείριση αιτήσεων",
      "headlineInfo": "Ερωτήματα συνεργατών",
      "bodytextInfo": "Παρακάτω θα βρείτε όλα τα ανοικτά ερωτήματα για επιμόρφωση",
      "newRequest": "Αποστολή δικής σας αίτησης",
      "tableHeadline01": "Παραλαβή",
      "tableHeadline02": "Αιτών",
      "tableHeadline03": "Αρ. προγράμματος",
      "tableHeadline04": "Τίτλος",
      "tableHeadline05": "Ημερομηνία",
      "tableHeadline06": "Τιμή",
      "tableMark": "Επισήμανση όλων",
      "buttonapprove": "Χορήγηση έγκρισης",
      "buttondecline": "Μη χορήγηση έγκρισης",
      "modal": {
        "refuse": {
          "headline": "Μήνυμα - Απόρριψη αιτήματος",
          "placeholder": "Καταχωρίστε ένα κείμενο....",
          "checkbox": "Απόρριψη αιτήματος χωρίς μήνυμα",
          "button": "Αποστολή",
          "approval": "Χορηγήθηκε έγκριση",
          "refuse": "Δεν χορηγήθηκε έγκριση"
        }
      }
    }
  },
  'projectBookingModal': {
    'headlines': {
      'chooseMeeting': 'Επιλέξτε ημερομηνία',
      'summaryMeeting': 'Επισκόπηση διορισμών',
      'summary': 'Περίληψη',
      'online': 'Περίληψη',
      'mixed': 'Περίληψη',
      'information': 'Επιλογές κράτησης'
    },
    'description': 'Παρακαλείστε να επαληθεύσετε τις επιλεγμένες πληροφορίες κράτησης πριν την υποβολή.'
  },
  'multiModal': {
    'buttons': {
      'cancel': 'Ακύρωση',
      'next': 'Περισσότερα',
      'back': 'Πίσω',
      'send': 'Υποβολή'
    }
  }
}
