// Portugiesisch

module.exports = {
  "main_navigation": {
    "login": "Iniciar Sessão",
    "registration": "Registo",
    "courses": "As minhas formações",
    "coursesDetail": "Detalhes da formação",
    "catalog": "Catálogo",
    "learningProfile": "Perfil de aprendizagem",
    "contact": "Contacto",
    "profile": "Perfil",
    "pwLost": "Esqueceu-se dos dados de Início de Sessão?",
    "about": "Informação Legal",
    "terms_and_conditions": "Termos de utilização / Política de privacidade",
    "logout": "Sair",
    "faq": "FAQ",
    "trainingmanager": "Pedidos",
    "trainingmanagerrequest": "Pedido",
    "trainingmanagermanage": "Gerir",
    "trainingmanagermanagedetail": "Detalhe"
  },
  "general": {
    'offline': {
      'headline': 'Você está offline!',
      'text': 'Esta aplicação precisa de uma ligação activa à Internet para funcionar correctamente.',
    },
    "year": "Ano",
    "month": "Mês",
    "noResult": "Não foi encontrado nenhum resultado.",
    "months": {
      "10": "Outubro",
      "11": "Novembro",
      "12": "Dezembro",
      "01": "Janeiro",
      "02": "Fevereiro",
      "03": "Março",
      "04": "Abril",
      "05": "Maio",
      "06": "Junho",
      "07": "Julho",
      "08": "Agosto",
      "09": "Setembro"
    },
    "category": "Categoria",
    "calendar": "Calendário",
    "search": "Pesquisa",
    "searchError": "Por favor, introduza pelo menos três caracteres.",
    "showLess": "Menos anúncios",
    "showMore": "Mais anúncios",
    'pagination': {
      'next': 'Próximo',
      'prev': 'Anterior',
      'separator': 'de',
      'pageSize': 'Itens por página',
    }
  },
  "maintenance": {
    "title": "Trabalhos de manutenção",
    "text": "Devido a trabalhos de manutenção, a Academia não está disponível de momento. Tente de novo mais tarde."
  },
  "password": {
    "requirements": {
      "title": "Diretivas de Palavra-passe",
      "minimalLength": "O comprimento deve ser de pelo menos #0.",
      "mandantName": "\"O termo \"\"#0\"\" não pode ser utilizado.\"",
      "names": "A palavra-passe não deve ter o seu nome e/ou apelido.",
      "userName": "A palavra-passe não deve conter os seus nomes de utilizador.",
      "userNameEqual": "A palavra-passe não deve conter os seus nomes de utilizador.",
      "bigLetters": "Mínimo #0 letra(s) maiúscula(s) é/são exigida(s).",
      "smallLetters": "Mínimo #0 letra(s) minúscula(s) é/são exigida(s).",
      "digits": "Mínimo #0 dígito(s) é/são exigido(s).",
      "sameLetters": "Não utilizar o mesmo caracter #0 vezes seguidas.",
      "oldPassword": "Pode não ser a palavra-passe antiga.",
      "birthday": "A palavra-passe pode não corresponder à sua própria data de nascimento.",
      "minSymbolsPolicy": "Mínimo #0 símbolo(s) é/são exigido(s).",
      "minPasswordLength": "A palavra-passe deve ter pelo menos # 0 caracteres.",
      "maxPasswordAttempts": "Ainda lhe restam #0 tentativas para introduzir a palavra-passe correta."
    }
  },
  'booking': {
    'cancel': {
      'title': 'Cancel booking',
      'confirm': 'are you sure you want to cancel this booking?'
    }
  },
  "pages": {
    "notFound": {
      "headline": "Pedimos desculpa!",
      "text1": "A página solicitada não foi encontrada",
      "text2": "Use o botão voltar no seu navegador ou vá para a página inicial.",
      "button": "Voltar à página inicial"
    },
    "courseCalendar": {
      "expired": "Expirado",
      "soonExpired": "A expirar em breve",
      'expiredMeetings': 'Datas passadas'
    },
    "profile": {
      "userdata": "Dados do utilizador",
      "password": "Palavra-passe",
      "genderLab": "Forma de tratamento",
      "gender": {
        "men": "Senhora",
        "woman": "Senhora",
        "diverse": "Diverso",
        "unknown": "Não especificado"
      },
      "firstname": "Primeiro Nome",
      "lastname": "Apelido",
      "email": "e-mail",
      "strasse": "Rua / N.º",
      "city": "Código postal / Localidade",
      "phone": "Telefone",
      "fax": "Fax",
      "position": "Cargo",
      "department": "Departamento",
      "required": "Campo Obrigatório",
      "oldPassword": "Palavra-passe antiga",
      "newPassword": "Nova palavra-passe",
      "newPasswordRep": "Repetir",
      "company": "Empresas",
      "language": "Idioma"
    },
    "catalog": {
      "back": "Voltar",
      "bookingDialog": {
        "notice": "Introduza aqui o seu pedido de formação"
      }
    },
    "contact": {
      "info": "Por favor, introduza o seu pedido na caixa de mensagens. Caso se refira a uma formação específica, especifique a mesma: A sua mensagem pode então ser encaminhada diretamente para o local certo.",
      "trainingSelection": "Seleção de formação",
      "yourMessage": "A sua mensagem",
      "success": "A sua mensagem foi enviada com sucesso!",
      "otherReason": "Pedido de contacto geral",
      "otherReasonSubject": "Outro pedido",
      "chooseReason": "Por favor, selecione"
    },
    "learningProfile": {
      "title": "Perfil de aprendizagem",
      "unit": "Unidades de aprendizagem",
      "points": "Pontuação total",
      "history": "Histórico de aprendizagem",
      "restoreFilter": "Repor filtro",
      "certificate": "Certificado",
      'downloadAll': 'Descarregar tudo',
      "withoutCategory": "Sem categoria",
      "tooltip01": "Concluiu",
      "tooltip02": "de",
      "tooltip03": "unidades de aprendizagem.",
      "gamification": {
        "badge": {
          "title": "Prémios",
          "congratulations": "Parabéns!",
          "achieved": "Recebeu um novo prémio!"
        }
      }
    },
    "courseDetail": {
      "title": "Detalhes da formação",
      'titleProject': 'Detalhes do projecto',
      "back": "Voltar à lista",
      "progress": "Progresso",
      "expiry": "válido até",
      "classroomNo": "Número de formação",
      "contactPerson": "Pessoa de contacto",
      "targetGroup": "Grupo-alvo",
      "bonusPoints": "Pontos de bónus",
      "classroomContent": "Conteúdo da formação",
      "webinarLight": "Informações sobre webinar",
      "specials": "Características especiais",
      "stornoConditions": "Condições de cancelamento",
      "meeting": "Datas",
      'meetingBooking': 'Datas do livro',
      'meetingRequests': 'Termine anfrage',
      'freeDay': 'Free day',
      'freeDayInfo': 'Dias livres não estão listados acima',
      "speaker": "Formador / Orador",
      "location": "Local de formação",
      "requested": "SOLICITADO",
      "waitlist": "LISTA DE ESPERA",
      "fullyBooked": "ESGOTADO",
      "edit": "Editar",
      "webinarSpeaker": "Orador",
      "docsAndLinks": "Documentos e ligações",
      "links": "Ligações",
      "webinar": {
        "host": "Abrir webinar",
        "state": {
          "ended": "Webinar está concluído",
          "beforeStart": "O webinar começa em "
        }
      },
      "sidebar": {
        "price": "Preço p. p.",
        "duration": "Duração",
        "to": "até",
        "numberOfParticipants": "Número de participantes",
        "status": "Estado da reserva",
        'totalStatus': 'Estado geral',
        'contentStatus': 'Situação da formação'
      },
      "bookingInformation": {
        "title": "Informação da reserva",
        "note": "Nota",
        "accounts": "Centro de custos",
        "invoiceRecipient": "Destinatário das faturas",
        "invoiceAddress": "Endereço de faturação",
        "food": "Preferência de refeições",
        "arrival": "Chegada",
        "arrivalType": {
          "none": "Não especificado",
          "car": "Caro",
          "train": "Comboio",
          "plane": "Avião"
        },
        "arrivalDate": "Data de chegada",
        "departure": "Partida",
        "departureDate": "Data de partida",
        "flightNumber": "Número de voo",
        "trainNumber": "Número do trem",
        "railwayStation": "Estação de Comboio",
        'placeholder': {
          'departure_airport': 'Aeroporto de partida',
          'destination_airport': 'Aeroporto de destino',
          'departure_trainstation': 'Estação de partida',
          'destination_trainstation': 'Estação de destino',
          'departure_car': 'Local de partida',
          'destination_car': 'Local de destino',
          'from': 'a partir de',
          'to': 'para'
        },
        'weekdays': [
          'Mon',
          'Tue',
          'Wed',
          'Thu',
          'Fri',
          'Sat',
          'Sun'
        ],
        'months': [
          "Janeiro",
          "Fevereiro",
          "Março",
          "Abril",
          "Maio",
          "Junho",
          "Julho",
          "Agosto",
          "Setembro",
          "Outubro",
          "Novembro",
          "Dezembro"
        ]
      },
      "reserve": {
        "title": "Não consegue fazer a(s) marcação(ões)?",
        "text": "Tome nota do seu interesse! Será então informado sobre possíveis novas datas.",
        "button": "Marcar",
        "prebooked": {
          "text": "Marcou o seu interesse por esta formação."
        }
      },
      'downloads': {
        'modalHeadline': 'Descargas',
        'sidebarLink': 'Documento(s)',
      }
    },
    "course": {
      "type": {
        "0": "Formação online",
        "1": "Formação online",
        "2": "Formação online",
        "3": "Formação online",
        "4": "Formação online",
        "5": "Formação online",
        "6": "Formação presencial",
        "7": "Webinar",
        "8": "Projeto",
        "undefined": "-"
      },
      "status": {
        "open": "Aberto",
        'sent': 'Sent',
        "passed": "Aprovado",
        'sent': 'Enviado',
        "failed": "Não Aprovado",
        'participated': 'Participated',
        'notParticipated': 'Not participated',
        "expired": "Expirado",
        "soonExpired": "A expirar em breve",
        "booked": "Reservado",
        "started": "Em processamento",
        "upcoming": "Pendente",
        "running": "Em curso",
        "completed": "Concluído",
        "notyet": "Ainda não disponível"
      },
      "notification": {
        "success1": "Completou a ",
        "success2": " formação!"
      },
      'warning': {
        'headline': 'Atenção!',
        'body': {
          '1': 'Seleccionou uma língua diferente da última vez para esta formação.',
          '2': 'Se prosseguir e clicar em \'Iniciar formação\', o progresso está a ser reiniciado.',
        },
        'buttons': {
          'start': 'Iniciar formação',
          'startPrevious': 'Seleccione a língua anterior',
        }
      },
      "statusLabel": {
        "training": "Formação",
        'content': 'Conteúdo',
        'attendance': 'Attendance',
        "exam": "Teste",
        "survey": "Questionário",
        "efficacyControl": "Controlo de eficácia"
      },
      "start": {
        "survey": "Iniciar questionário",
        "exam": "Iniciar teste",
        "efficacyControl": "Iniciar controlo da eficácia"
      },
      "prefix": {
        "category": "Categoria",
        "state": "Estado",
        "type": "Tipo de formação"
      },
      "noAssigned": "Não tem qualquer formação atribuída.  ",
      "noSolved": "\"Ainda não completou nenhuma formação. Inicie agora na área \"\"As minhas formações\"\" a sua primeira unidade de aprendizagem!\"",
      "noAssignedBookable": "Não tem formações reserváveis atribuídas.",
      "noResult": "Não foram encontradas formações que correspondam aos critérios.",
      "loadingFailed": "Os dados solicitados não puderam ser carregados devido a um erro desconhecido!",
      "noResultInCategory": "Ainda não foram atribuídas formações a esta categoria.",
      "meeting": {
        "noMeeting": "sem data",
        "multipleMeetings": "div. Datas"
      },
      "project": {
        "courses": "Formações"
      }
    },
    "login": {
      "invalidUser": "Início de sessão inválido. Palavra-passe/Nome de utilizador está incorreto!",
      "invalidPassword": "Início de sessão inválido. Ainda lhe restam #0 tentativas para introduzir a palavra-passe correta.",
      "accountBlocked": "A sua conta foi bloqueada. Contacte um funcionário responsável.",
      "error": "O nome de utilizador e/ou palavra-passe está incorreto!",
      "empty": "Por favor, preencha todos os campos.",
      "back": "Voltar ao Início de Sessão",
      "description": "Por favor, introduza o seu nome de utilizador ou endereço de e-mail para receber novamente os seus dados de início de sessão.",
      "password-reset": {
        "description": "Por favor, escolha uma nova palavra-passe para a sua conta de utilizador.",
        "success": "A sua palavra-passe foi alterada com sucesso.",
        "error": "As palavras-passe que introduziu não coincidem."
      },
      "placeholder": " Nome de utilizador / endereço de e-mail",
      "button": "Solicitar dados de acesso",
      "success": "Solicitação enviada com sucesso.",
      "errorForgot": "Não foi possível encontrar nenhum utilizador que corresponda aos critérios especificados.",
      "errorForgotSecurityQuestion": "Infelizmente, a resposta está incorreta!"
    },
    "registration": {
      "description": "Se ainda não tiver quaisquer dados de início de sessão, pode simplesmente registar-se usando o código promocional da sua empresa. Ser-lhe-á enviado então um e-mail imediatamente.",
      "actionCodeError": "O código de registo que introduziu é inválido",
      "actionCodeLimitExceeded": "O número máximo de registos possíveis para o código de promoção introduzido foi esgotado.",
      "registerDescription": "Por favor, preencha o formulário abaixo. Os seus dados de acesso ser-lhe-ão enviados por e-mail dentro de alguns minutos.",
      "title": "A sua inscrição na Academia",
      "required": "Campo Obrigatório*",
      "emailError": "O endereço de e-mail que introduziu já existe.",
      "emailErrorNotAllowed": "O registo não é possível com este endereço de e-mail.",
      "send": "Enviar",
      "completeMessage": "Inscreveu-se com sucesso na Academia!"
    },
    "dataProtection": {
      "accept": "Concordar",
      "acceptText": "Sim, concordo com os termos de utilização / política de privacidade"
    },
    "securityQuestion": {
      "title": "Pergunta de segurança",
      "chooseQuestion": "Por favor, selecione",
      "questionSelection": "Por favor, selecione uma pergunta",
      "yourAnswer": "Por favor, introduza a sua resposta",
      "description": "Responda à pergunta de segurança e escolha uma nova palavra-passe para a sua conta de utilizador."
    },
    "faq": {
      "title": "Perguntas mais frequentes"
    }
  },
  "sidebar": {
    "all": "Todos",
    "categories": "CATEGORIAS",
    "status": "ESTADO",
    "courseType": "TIPO DE FORMAÇÃO",
    "topics": "TÓPICOS"
  },
  "placeholder": {
    "username": "Nome de utilizador",
    "password": "Palavra-passe",
    "actionCode": "Código de ação"
  },
  "button": {
    "login": "INICIAR SESSÃO",
    "login-sso": "INÍCIO DE SESSÃO NO DIRETÓRIO ATIVO",
    "send": "ENVIAR",
    "start_course": "INICIAR",
    "changePassword": "Alterar palavra-passe",
    "reset": "Repor",
    "save": "Guardar",
    "request": "Pedido",
    "storno": "Cancelar",
    "close": "Fechar",
    "booking": "Reservar",
    "waitlist": "Lista de espera",
    "start_webinar": "Iniciar webinar",
    "open_webinar": "Abrir webinar",
    'cancel': 'Cancelar',
    'confirm': 'Confirmar'
  },
  "bookingState": {
    "0": "SOLICITADO",
    "1": "RESERVADO",
    "2": "LISTA DE ESPERA",
    "3": "CANCELADO",
    "4": "CANCELADO",
    "5": "RECUSADO",
    "6": "MARCADO",
    "onlineTraining": {
      "0": "SOLICITADO",
      "1": "RESERVADO",
      "2": "RECUSADO"
    }
  },
  "foodPreference": {
    "none": "Nenhum",
    "lactoseIntolerance": "Intolerância à lactose",
    "vegetarian": "Vegetariano",
    "vegan": "Vegan",
    "nutAllergy": "Alergia a nozes",
    "diabetic": "Diabetes",
    "glutenFree": "Sem glúten",
    "kosher": "Kosher",
    "halal": "Halal",
    "other": "Outros"
  },
  "notifications": {
    "error": "Algo correu mal!",
    "errors": {
      "missingInput": "Por favor, preencha os campos.",
      "imageLoad": "Não foi possível carregar a imagem solicitada."
    },
    "success": {
      "book": {
        "meeting": "Reservou com êxito a formação presencial.",
        "webinar": "Reservou com êxito o webinar.",
        "course": "Reservou com êxito o seminário.",
        "project": "Reservou com êxito o projeto."
      },
      "request": {
        "meeting": "Enviou com êxito um pedido de reserva para a formação presencial.",
        "webinar": "Enviou com sucesso um pedido de reserva para o webinar.",
        "course": "Enviou com sucesso um pedido de reserva para a formação.",
        "project": "Enviou com sucesso um pedido de reserva para o projeto."
      },
      "profile": {
        "updateSuccess": "Atualizou o seu perfil com êxito!",
        "updatePasswordSuccess": "Atualizou a sua palavra-passe com êxito!"
      },
      "securityQuestion": {
        "questionSave": "A sua pergunta de segurança foi guardada!"
      }
    }
  },
  "webservice": {
    "error": {
      "duplicate": "A reserva já existe!",
      "noaccess": "Acesso não é permitido.",
      "notimplementet": "A função não está disponível neste momento."
    },
    "success": {
      "booking": "A sua reserva foi efetuada com sucesso.",
      "request": "Enviou com sucesso um pedido de reserva.",
      "cancel": "Cancelou com sucesso. ",
      "waitlist": {
        "add": "Foi colocada em lista de espera"
      },
      "reservelist": {
        "add": "Foi colocada na lista de pré-reserva"
      }
    },
    "courseCalendar": {
      "noDate": "Sem prazo"
    }
  },
  "trainingmanager": {
    "headline": "Pedido de formação complementar",
    "headlinerequest": "Submeter pedido",
    "bottonrequest": "Submeter pedido",
    "buttonmanage": "Gerir pedidos",
    "requestform": {
      "requester": "Solicitante:",
      "date": "Data:",
      "tab01": "Apresentar pedido",
      "tab02": "Pedido para grupo / terceiros",
      "tab01headline01": "Nome do participante:",
      "tab01headline02": "Tópico de formação complementar:",
      "tab01headline04": "Nomes dos participantes:",
      "tab01headline03": "Detalhes do curso:",
      "tab01label01": "Forma de tratamento:",
      "tab01label02": "Nome próprio:",
      "tab01label03": "Nome:",
      "tab01label04": "Senhora",
      "tab01label05": "Senhora",
      "tab01label06": "Categoria:",
      "tab01label07": "Título*:",
      "tab01label08": "Descrição breve:",
      "tab01label09": "Outros:",
      "tab01label09O": "Outros",
      "tab01label10": "Centro de custos:",
      "tab01label11": "Data (de/até):",
      "tab01label12": "Número do curso:",
      "tab01label13": "Preço:",
      "tab01label14": "Fornecedor:",
      "tab01label15": "Anexo:",
      "tab01label16": "Selecionar ficheiro",
      "tab01label17": "Nenhum ficheiro selecionado...",
      "tab01label18": "Enviar pedido",
      "tab01label19": "Adicionar outra pessoa",
      "tab01label20": "Cancelar",
      "olheadline": "Adicionar participante",
      "olheadline1": "Nome do participante",
      "olbodytext": "Por favor, selecione os outros participantes do curso abaixo.",
      "olerror": "Nenhum participantes encontrado. Por favor, altere os parâmetros de pesquisa."
    },
    "manage": {
      "headline": "Gerir pedidos",
      "headlineInfo": "Pedidos de funcionários",
      "bodytextInfo": "De seguida encontrará todos os pedidos de formação em aberto com pedido de processamento.",
      "newRequest": "Enviar próprio pedido",
      "tableHeadline01": "Entrada",
      "tableHeadline02": "Solicitante",
      "tableHeadline03": "N.º curso",
      "tableHeadline04": "Título",
      "tableHeadline05": "Data",
      "tableHeadline06": "Preço",
      "tableMark": "Marcar todos",
      "buttonapprove": "Conceder aprovação",
      "buttondecline": "Não conceder aprovação",
      "modal": {
        "refuse": {
          "headline": "Mensagem - Rejeitar pedido",
          "placeholder": "Por favor, introduza um texto....",
          "checkbox": "Rejeitar pedido sem mensagem",
          "button": "Enviar",
          "approval": "Aprovação concedida",
          "refuse": "Aprovação não concedida"
        }
      }
    }
  },
  'projectBookingModal': {
    'headlines': {
      'chooseMeeting': 'Escolha a data',
      'summaryMeeting': 'Visão geral das nomeações',
      'summary': 'Resumo',
      'online': 'Visão geral',
      'mixed': 'Visão geral',
      'information': 'Opções de reserva'
    },
    'description': 'Por favor, verifique as informações de reserva seleccionadas antes de submeter.'
  },
  'multiModal': {
    'buttons': {
      'cancel': 'Cancelar',
      'next': 'Mais',
      'back': 'Voltar',
      'send': 'Submeter'
    }
  }
}
