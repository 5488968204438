// Rumänisch

module.exports = {
  "main_navigation": {
    "login": "Autentificare",
    "registration": "Înregistrare",
    "courses": "Instruirile mele",
    "coursesDetail": "Detalii instruire",
    "catalog": "Catalog",
    "learningProfile": "Profil de învăţare",
    "contact": "Contact",
    "profile": "Profil",
    "pwLost": "Aţi uitat datele de autentificare?",
    "about": "Menţiuni legale",
    "terms_and_conditions": "Condiţii de utilizare / Protecţia datelor",
    "logout": "Delogare",
    "faq": "FAQ",
    "trainingmanager": "Cereri",
    "trainingmanagerrequest": "Solicitare",
    "trainingmanagermanage": "Administrare",
    "trainingmanagermanagedetail": "Detaliu"
  },
  "general": {
    'offline': {
      'headline': 'Sunteți offline!',
      'text': 'Această aplicație are nevoie de o conexiune activă la internet pentru a funcționa corect.',
    },
    "year": "An",
    "month": "Luna",
    "noResult": "Nu a fost găsit niciun rezultat.",
    "months": {
      "10": "Octombrie",
      "11": "Noiembrie",
      "12": "Decembrie",
      "01": "Ianuarie",
      "02": "Februarie",
      "03": "Martie",
      "04": "Aprilie",
      "05": "Mai",
      "06": "Iunie",
      "07": "Iulie",
      "08": "August",
      "09": "Septembrie"
    },
    "category": "Categorie",
    "calendar": "Calendar",
    "search": "Căutare",
    "searchError": "Vă rugăm să introduceți cel puțin trei caractere.",
    "showLess": "Mai puţine afişări",
    "showMore": "Mai multe afişări",
    'pagination': {
      'next': 'Următorul',
      'prev': 'Anterior',
      'separator': 'din',
      'pageSize': 'Articole pe pagină'
    }
  },
  "maintenance": {
    "title": "Lucrări de întreţinere",
    "text": "Datorită lucrărilor de întreţinere, academia nu este disponibilă în acest moment. Vă rugăm să încercaţi din nou mai târziu."
  },
  "password": {
    "requirements": {
      "title": "Politica privind parola",
      "minimalLength": "Lungimea trebuie să fie de cel puţin #0.",
      "mandantName": "\"Termenul \"\"#0\"\" nu trebuie utilizat.\"",
      "names": "Parola nu trebuie să fie aceeaşi cu prenumele sau numele.",
      "userName": "Parola nu trebuie să conţină numele tău de utilizator.",
      "userNameEqual": "Parola nu trebuie să conţină numele tău de utilizator.",
      "bigLetters": "Trebuie să existe cel puţin #0 litere mari.",
      "smallLetters": "Trebuie să existe cel puţin #0 litere mici.",
      "digits": "Trebuie să existe cel puţin #0 cifră/cifre.",
      "sameLetters": "Nu trebuie să urmeze mai mult decât #0 caractere unul după altul.",
      "oldPassword": "Nu trebuie să fie parola veche.",
      "birthday": "Parola nu trebuie să corespundă datei tale de naştere.",
      "minSymbolsPolicy": "Trebuie să existe cel puţin #0 caractere speciale.",
      "minPasswordLength": "Parola trebuie să aibă o lungime de cel puţin #0 caractere.",
      "maxPasswordAttempts": "V-au mai rămas încă #0 încercări pentru a introduce în mod corect parola."
    }
  },
  'booking': {
    'cancel': {
      'title': 'Cancel booking',
      'confirm': 'are you sure you want to cancel this booking?'
    }
  },
  "pages": {
    "notFound": {
      "headline": "Ne pare rău!",
      "text1": "Pagina solicitată nu a fost găsită",
      "text2": "Folosiţi tasta înapoi a browser-ului dumneavoastră sau mergeţi la pagina iniţială.",
      "button": "Înapoi la pagina iniţială"
    },
    "courseCalendar": {
      "expired": "Expirat",
      "soonExpired": "Expiră în curând",
      'expiredMeetings': 'Datele anterioare'
    },
    "profile": {
      "userdata": "Datele utilizatorului",
      "password": "Parola",
      "genderLab": "Titlu",
      "gender": {
        "men": "Domnul",
        "woman": "Doamna",
        "diverse": "Divers",
        "unknown": "Nespecificat"
      },
      "firstname": "Prenume",
      "lastname": "Nume de familie",
      "email": "E-mail",
      "strasse": "Strada / Nr.",
      "city": "Cod poştal / Localitate",
      "phone": "Telefon",
      "fax": "Fax",
      "position": "Funcţia",
      "department": "Departamentul",
      "required": "Câmp obligatoriu",
      "oldPassword": "Parola veche",
      "newPassword": "Parola nouă",
      "newPasswordRep": "Repetare",
      "company": "Companie",
      "language": "Limba"
    },
    "catalog": {
      "back": "Înapoi",
      "bookingDialog": {
        "notice": "Introduceţi aici motivarea dumneavoastră pentru cererea de instruire"
      }
    },
    "contact": {
      "info": "Vă rugăm să introduceţi cererea dumneavoastră în câmpul pentru mesaj. Dacă dumneavoastră vă referiţi la o instruire specială, vă rugăm să o menţionaţi: Astfel mesajul dumneavoastră poate direcţionat către departamentul corect.",
      "trainingSelection": "Selecţie instruire",
      "yourMessage": "Mesajul dumneavoastră",
      "success": "Mesajul dumneavoastră a fost expediat cu succes!",
      "otherReason": "Solicitare de contact generală",
      "otherReasonSubject": "Altă solicitare",
      "chooseReason": "Vă rugăm să selectaţi"
    },
    "learningProfile": {
      "title": "Profil de învăţare",
      "unit": "Unităţi de învăţare",
      "points": "Punctaj total",
      "history": "Istoricul învățării",
      "restoreFilter": "Resetaţi filtrul",
      "certificate": "Certificat",
      'downloadAll': 'Descărcați toate',
      "withoutCategory": "Fără categorie",
      "tooltip01": "Ați promovat",
      "tooltip02": "din",
      "tooltip03": "unităţile de învăţare.",
      "gamification": {
        "badge": {
          "title": "Distincții",
          "congratulations": "Felicitări!",
          "achieved": "Aţi primit o nouă distincţie!"
        }
      }
    },
    "courseDetail": {
      "title": "Detalii referitoare la instruire",
      'titleProject': 'Detalii despre proiect',
      "back": "Înapoi la listă",
      "progress": "Parcurs",
      "expiry": "valabil până la",
      "classroomNo": "Numărul pregătirii",
      "contactPerson": "Persoana de contact",
      "targetGroup": "Grupul ţintă",
      "bonusPoints": "Puncte bonus",
      "classroomContent": "Conţinuturile pregătirii",
      "webinarLight": "Seminar pe internet informaţii",
      "specials": "Specificaţii",
      "stornoConditions": "Condiţii de anulare",
      "meeting": "Programări",
      'meetingBooking': 'Rezervați datele',
      'meetingRequests': 'Cerere de date',
      'freeDay': 'Free day',
      'freeDayInfo': 'Zilele libere nu sunt enumerate mai sus\n',
      "speaker": "Instructor / Referent",
      "location": "Locaţia instruirii",
      "requested": "SOLICITAT",
      "waitlist": "LISTĂ DE AŞTEPTARE",
      "fullyBooked": "OCUPAT",
      "edit": "Prelucrează",
      "webinarSpeaker": "Referent",
      "docsAndLinks": "Documente şi link-uri",
      "links": "Link-uri",
      "webinar": {
        "host": "Deschideţi seminarul pe internet",
        "state": {
          "ended": "Seminarul pe internet s-a terminat",
          "beforeStart": "Seminarul pe internet începe în data de "
        }
      },
      "sidebar": {
        "price": "Preț pe persoană",
        "duration": "Timp de învățare",
        "to": "până la",
        "numberOfParticipants": "Număr participanţi",
        "status": "Stare rezervare",
        'totalStatus': 'Starea generală',
        'contentStatus': 'Statutul de formare'
      },
      "bookingInformation": {
        "title": "Informaţii de rezervare",
        "note": "Observaţie",
        "accounts": "Poziţie costuri",
        "invoiceRecipient": "Destinatarul facturii",
        "invoiceAddress": "Adresa de facturare",
        "food": "Serviciul de restaurant preferat",
        "arrival": "Sosire",
        "arrivalType": {
          "none": "Nespecificat",
          "car": "Autoturism",
          "train": "Tren",
          "plane": "Avion"
        },
        "arrivalDate": "Data de sosire",
        "departure": "Plecare",
        "departureDate": "Data de plecare",
        "flightNumber": "Număr de zbor",
        "trainNumber": "Numărul trenului",
        "railwayStation": "Gară",
        'placeholder': {
          'departure_airport': 'Aeroportul de plecare',
          'destination_airport': 'Aeroportul de destinație',
          'departure_trainstation': 'Stația de plecare',
          'destination_trainstation': 'Gara de destinație',
          'departure_car': 'Locul de plecare',
          'destination_car': 'Locul de destinație',
          'from': 'de la',
          'to': 'la'
        },
        'weekdays': [
          'Mon',
          'Tue',
          'Wed',
          'Thu',
          'Fri',
          'Sat',
          'Sun'
        ],
        'months': [
          "Ianuarie",
          "Februarie",
          "Martie",
          "Aprilie",
          "Mai",
          "Iunie",
          "Iulie",
          "August",
          "Septembrie",
          "Octombrie",
          "Noiembrie",
          "Decembrie"
        ]
      },
      "reserve": {
        "title": "Nu puteţi să stabiliţi programarea/programările?",
        "text": "Notaţi interesul dumneavoastră! Veţi fi informat atunci cu privire la noile programări posibile?",
        "button": "Notaţi preventiv",
        "prebooked": {
          "text": "Dumneavoastră aţi notat preventiv interesul pentru această instruire."
        }
      },
      'downloads': {
        'modalHeadline': 'Descărcări',
        'sidebarLink': 'Document(e)',
      }
    },
    "course": {
      "type": {
        "0": "Instruire online",
        "1": "Instruire online",
        "2": "Instruire online",
        "3": "Instruire online",
        "4": "Instruire online",
        "5": "Instruire online",
        "6": "Instruire cu prezenţă",
        "7": "Seminar pe internet",
        "8": "Proiect",
        "undefined": "-"
      },
      "status": {
        "open": "Deschis",
        'sent': 'Sent',
        "passed": "Promovat",
        'sent': 'Simt',
        "failed": "Nu este promovat",
        'participated': 'Participated',
        'notParticipated': 'Not participated',
        "expired": "Expirat",
        "soonExpired": "Expiră în curând",
        "booked": "Rezervat",
        "started": "În procesare",
        "upcoming": "Iminent",
        "running": "În curs",
        "completed": "Încheiat",
        "notyet": "Nu este disponibil încă"
      },
      "notification": {
        "success1": "Ați promovat cu succes ",
        "success2": " instruirea!"
      },
      'warning': {
        'headline': 'Atenție!',
        'body': {
          '1': 'Ați selectat o altă limbă decât ultima dată pentru acest curs.',
          '2': 'Dacă continuați și faceți clic pe "Start training", progresul este resetat.',
        },
        'buttons': {
          'start': 'Start training',
          'startPrevious': 'Selectați limba anterioară',
        }
      },
      "statusLabel": {
        "training": "Instruire",
        'content': 'Conținut',
        'attendance': 'Attendance',
        "exam": "Examen",
        "survey": "Chestionare",
        "efficacyControl": "Test de eficacitate"
      },
      "start": {
        "survey": "Începe chestionarea",
        "exam": "Începe examenul",
        "efficacyControl": "Începe testul de eficacitate"
      },
      "prefix": {
        "category": "Categorie",
        "state": "Stare",
        "type": "Tipul instruirii"
      },
      "noAssigned": "Nu v-a fost alocată nicio instruire.",
      "noSolved": "\"Nu aţi absolvit încă nicio instruire. Începeţi acum în secţiunea „Instruirile mele\"\" cu prima unitate de învăţare!\"",
      "noAssignedBookable": "Nu v-a fost alocată nicio instruire disponibilă.",
      "noResult": "Nu s-a găsit nicio instruire care corespunde criteriilor.",
      "loadingFailed": "Seturile de date solicitate nu au putut fi încărcate datorită unei erori necunoscute!",
      "noResultInCategory": "Nu a fost alocată nicio instruire în această categorie.",
      "meeting": {
        "noMeeting": "nicio programare",
        "multipleMeetings": "diferite Programări"
      },
      "project": {
        "courses": "Instruiri"
      }
    },
    "login": {
      "invalidUser": "Autentificare invalidă. Numele de utilizator / parola este falsă!",
      "invalidPassword": "Autentificare invalidă. V-au mai rămas încă #0 încercări pentru a introduce în mod corect parola.",
      "accountBlocked": "Contul dumneavoastră a fost blocat. Vă rugăm să vă adresaţi unui angajat responsabil.",
      "error": "Nume de utilizator şi/sau parolă falsă!",
      "empty": "Vă rugăm să completaţi toate câmpurile.",
      "back": "Înapoi la autentificare",
      "description": "Vă rugăm să introduceţi numele dumneavoastră de utilizator sau adresa dumneavoastră de e-mail pentru o nouă expediere a datelor de acces.",
      "password-reset": {
        "description": "Vă rugăm să selectaţi o parolă nouă pentru contul dumneavoastră de utilizator.",
        "success": "Parola dumneavoastră a fost modificată cu succes.",
        "error": "Parolele introduse nu corespund între ele."
      },
      "placeholder": " Nume de utilizator / adresa de e-mail",
      "button": "Solicitaţi datele de acces",
      "success": "Solicitarea a fost expediată cu succes.",
      "errorForgot": "Nu s-a putut găsi niciun utilizator, care corespunde criteriilor introduse.",
      "errorForgotSecurityQuestion": "Răspunsul nu este din păcate corect!"
    },
    "registration": {
      "description": "Dacă dumneavoastră nu aveţi încă date de autentificare, puteţi să vă înregistraţi simplu prin codul acţiune al companiei dumneavoastră. După aceasta dumneavoastră veţi primi imediat un e-mail.",
      "actionCodeError": "Codul de înregistrare introdus nu este valid",
      "actionCodeLimitExceeded": "Numărul maxim de înregistrări posibile pentru codul acţiune introdus este epuizat.",
      "registerDescription": "Vă rugăm să completaţi formularul de mai jos. Datele dumneavoastră de acces vă vor fi expediate în câteva minute prin e-mail.",
      "title": "Înregistrarea academiei dumneavoastră",
      "required": "Câmp obligatoriu*",
      "emailError": "Adresa de e-mail introdusă de dumneavoastră a fost alocată deja.",
      "emailErrorNotAllowed": "Cu această adresă de e-mail nu este posibilă o înregistrare.",
      "send": "Expediază",
      "completeMessage": "Dumneavoastră v-aţi înregistrat cu succes pentru academie!"
    },
    "dataProtection": {
      "accept": "Acceptă",
      "acceptText": "Da, sunt de acord cu condiţiile de utilizare / protecţia datelor"
    },
    "securityQuestion": {
      "title": "Întrebare de securitate",
      "chooseQuestion": "Vă rugăm să selectaţi",
      "questionSelection": "Vă rugăm să selectaţi o întrebare",
      "yourAnswer": "Vă rugăm să introduceţi răspunsul dumneavoastră",
      "description": "Vă rugăm să răspundeţi la întrebarea de securitate şi selectaţi o parolă nouă pentru contul dumneavoastră de utilizator."
    },
    "faq": {
      "title": "Întrebări frecvente"
    }
  },
  "sidebar": {
    "all": "Toate",
    "categories": "CATEGORII",
    "status": "STARE",
    "courseType": "TIPUL INSTRUIRII",
    "topics": "DOMENII DE SUBIECTE"
  },
  "placeholder": {
    "username": "Nume de utilizator",
    "password": "Parola",
    "actionCode": "Cod acţiune"
  },
  "button": {
    "login": "ÎNREGISTRARE",
    "login-sso": "AUTENTIFICARE ACTIVE-DIRECTORY",
    "send": "EXPEDIAZĂ",
    "start_course": "ÎNCEPUT",
    "changePassword": "Schimbă parola",
    "reset": "Resetează",
    "save": "Salvează",
    "request": "Solicitare",
    "storno": "Anulare",
    "close": "Închide",
    "booking": "Rezervă",
    "waitlist": "Listă de aşteptare",
    "start_webinar": "Începe seminarul pe internet",
    "open_webinar": "Deschide seminarul pe internet",
    'cancel': 'Anulează',
    'confirm': 'Confirmați'
  },
  "bookingState": {
    "0": "SOLICITAT",
    "1": "REZERVAT",
    "2": "LISTĂ DE AŞTEPTARE",
    "3": "ANULAT",
    "4": "ANULAT",
    "5": "RESPINS",
    "6": "PRENOTAT",
    "onlineTraining": {
      "0": "SOLICITAT",
      "1": "REZERVAT",
      "2": "RESPINS"
    }
  },
  "foodPreference": {
    "none": "Fără",
    "lactoseIntolerance": "Intoleranţă la lactoză",
    "vegetarian": "Vegetarian",
    "vegan": "Vegan",
    "nutAllergy": "Alergie la nuci",
    "diabetic": "Diabet",
    "glutenFree": "Fără gluten",
    "kosher": "Cuşer",
    "halal": "Halal",
    "other": "Altele"
  },
  "notifications": {
    "error": "Ceva n-a mers bine!",
    "errors": {
      "missingInput": "Vă rugăm să completaţi câmpurile",
      "imageLoad": "Imaginea solicitată nu a putut fi încărcată"
    },
    "success": {
      "book": {
        "meeting": "Ați rezervat cu succes instruirea cu prezenţă.",
        "webinar": "Ați rezervat cu succes seminarul pe internet.",
        "course": "Ați rezervat cu succes instruirea.",
        "project": "Ați rezervat cu succes proiectul."
      },
      "request": {
        "meeting": "Ați solicitat cu succes instruirea cu prezenţă.",
        "webinar": "Ați solicitat cu succes seminarul pe internet.",
        "course": "Ați solicitat cu succes instruirea.",
        "project": "Ați solicitat cu succes proiectul."
      },
      "profile": {
        "updateSuccess": "V-aţi actualizat cu succes profilul!",
        "updatePasswordSuccess": "Aţi actualizat cu succes parola!"
      },
      "securityQuestion": {
        "questionSave": "Întrebarea dumneavoastră de securitate a fost salvată!"
      }
    }
  },
  "webservice": {
    "error": {
      "duplicate": "Rezervarea există deja!",
      "noaccess": "Accesul nu este permis.",
      "notimplementet": "Funcţiunea nu este disponibilă în prezent."
    },
    "success": {
      "booking": "Ați rezervat cu succes.",
      "request": "Ați solicitat cu succes.",
      "cancel": "Ați anulat cu succes. ",
      "waitlist": {
        "add": "Aţi fost pus pe lista de aşteptare"
      },
      "reservelist": {
        "add": "Aţi fost pus pe lista cu notiţe"
      }
    },
    "courseCalendar": {
      "noDate": "Fără termen"
    }
  },
  "trainingmanager": {
    "headline": "Solicitare de continuare a pregătirii",
    "headlinerequest": "Înaintează cererea",
    "bottonrequest": "Înaintează cererea",
    "buttonmanage": "Administrează cererile",
    "requestform": {
      "requester": "Aplicant:",
      "date": "Data:",
      "tab01": "Înaintează solicitarea",
      "tab02": "Solicitare pentru grup/persoana terţă",
      "tab01headline01": "Numele participantului:",
      "tab01headline02": "Subiectul continuării pregătirii:",
      "tab01headline04": "Numele participanţilor:",
      "tab01headline03": "Detalii curs:",
      "tab01label01": "Titlu:",
      "tab01label02": "Prenume:",
      "tab01label03": "Nume:",
      "tab01label04": "Domnul",
      "tab01label05": "Doamna",
      "tab01label06": "Categorie:",
      "tab01label07": "Titlu*:",
      "tab01label08": "Descriere scurtă:",
      "tab01label09": "Altele:",
      "tab01label09O": "Altele",
      "tab01label10": "Centru de costuri:",
      "tab01label11": "Programare (de la/până la):",
      "tab01label12": "Număr curs:",
      "tab01label13": "Preț:",
      "tab01label14": "Ofertant:",
      "tab01label15": "Anexa:",
      "tab01label16": "Selectează fişierul",
      "tab01label17": "Niciun fişier selectat...",
      "tab01label18": "Expediază cererea",
      "tab01label19": "Mai adaugă o persoană",
      "tab01label20": "Întrerupe",
      "olheadline": "Mai adaugă un participant",
      "olheadline1": "Numele participantului",
      "olbodytext": "Selectaţi în continuare numele altor participanţi la instruire.",
      "olerror": "Nu a fost găsit niciun participant. Vă rugăm să modificaţi parametrii dumneavoastră de căutare."
    },
    "manage": {
      "headline": "Administrează cererile",
      "headlineInfo": "Solicitări angajaţi",
      "bodytextInfo": "În continuare veţi găsi toate solicitările de continuare a pregătirii cu rugămintea de procesare.",
      "newRequest": "Expediază propria cerere",
      "tableHeadline01": "Intrare",
      "tableHeadline02": "Aplicant",
      "tableHeadline03": "Nr. curs",
      "tableHeadline04": "Titlu",
      "tableHeadline05": "Programare",
      "tableHeadline06": "Preț",
      "tableMark": "Marchează pe toate",
      "buttonapprove": "Acordă aprobarea",
      "buttondecline": "Nu acordă autorizarea",
      "modal": {
        "refuse": {
          "headline": "Respinge solicitarea - mesaj",
          "placeholder": "Vă rugăm să introduceţi un text...",
          "checkbox": "Respinge solicitarea fără mesaj",
          "button": "Expediază",
          "approval": "Aprobare acordată",
          "refuse": "Autorizare neacordată"
        }
      }
    }
  },
  'projectBookingModal': {
    'headlines': {
      'chooseMeeting': 'Alegeți data',
      'summaryMeeting': 'Alegeți data',
      'summary': 'Rezumat',
      'online': 'Prezentare generală',
      'mixed': 'Prezentare generală',
      'information': 'Opțiuni de rezervare'
    },
    'description': 'Vă rugăm să verificați informațiile de rezervare selectate înainte de a le trimite.'
  },
  'multiModal': {
    'buttons': {
      'cancel': 'Anulează',
      'next': 'Mai mult',
      'back': 'Înapoi',
      'send': 'Trimiteți'
    }
  }
}
