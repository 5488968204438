// Italienisch

module.exports = {
  "main_navigation": {
    "login": "Accesso",
    "registration": "Registrazione",
    "courses": "La mia formazione",
    "coursesDetail": "Dettagli della formazione",
    "catalog": "Catalogo",
    "learningProfile": "Profilo di apprendimento",
    "contact": "Contatti",
    "profile": "Profilo",
    "pwLost": "Hai dimenticato i dati di accesso?",
    "about": "",
    "terms_and_conditions": "Termini di utilizzo/Protezione dei dati",
    "logout": "Esci",
    "trainingmanager": "Richieste",
    "trainingmanagerrequest": "Richiesta",
    "trainingmanagermanage": "Gestisci",
    "trainingmanagermanagedetail": "Dettagli",
    'faq': 'FAQ'
  },
  "general": {
    'offline': {
      'headline': 'Sei offline!',
      'text': 'Per funzionare correttamente, questa applicazione necessita di una connessione Internet attiva.',
    },
    "year": "Anno",
    "month": "Mese",
    "months": {
      "10": "Ottobre",
      "11": "Novembre",
      "12": "Dicembre",
      "01": "Gennaio",
      "02": "Febbraio",
      "03": "Marzo",
      "04": "Aprile",
      "05": "Maggio",
      "06": "Giugno",
      "07": "Luglio",
      "08": "Agosto",
      "09": "Settembre"
    },
    "category": "Categoria",
    "calendar": "Calendario",
    'search': 'Cerca',
    "searchError": "Si prega di inserire almeno tre caratteri.",
    "showLess": "Mostra meno",
    "showMore": "Mostra di più",
    'pagination': {
      'next': 'Következő',
      'prev': 'Precedente',
      'separator': 'di',
      'pageSize': 'Articoli per pagina',
    }
  },
  "maintenance": {
    "title": "Mantenimento",
    "text": "L'Akademie è al momento non disponibile per manutenzione. Prova più tardi"
  },
  'booking': {
    'cancel': {
      'title': 'Cancel booking',
      'confirm': 'are you sure you want to cancel this booking?'
    }
  },
  "pages": {
    'notFound': {
      'headline': 'Siamo spiacenti!',
      'text1': 'La pagina che stai cercando non è stata trovata.',
      'text2': 'Utilizza il tasto indietro del tuo browser oppure torna alla pagina iniziale.',
      'button': 'Torna alla pagina iniziale'
    },
    "courseCalendar": {
      "expired": "Scaduto",
      "soonExpired": "Scadenza vicina",
      'expiredMeetings': 'Date passate'
    },
    "profile": {
      "userdata": "Informazioni Utente",
      "password": "Password",
      "genderLab": "Saluto",
      "gender": {
        "men": "Signor",
        "woman": "Signora",
        "diverse": "Altro",
        "unknown": "Non specificato"
      },
      "firstname": "Nome",
      "lastname": "Cognome",
      "email": "Email",
      "strasse": "Via / numero",
      "city": "CAP",
      "phone": "Telefono",
      "fax": "Fax",
      "position": "Posizione Lavorativa",
      "department": "Dipartimento",
      "required": "Campo richieso",
      "oldPassword": "Vecchia password",
      "newPassword": "Nuova password",
      "newPasswordRep": "Ripeti password",
      "company": "Azienda",
      "language": "Linguaggio"
    },
    "catalog": {
      "back": "Indietro",
      "bookingDialog": {
        "notice": "Scrivi qui la tua richiesta"
      }
    },
    "contact": {
      "info": "Inserisci la tua richiesta nel campo del messaggio. Se fai riferimento a un corso di formazione, specificalo. Il tuo messaggio potrà  essere inoltrato direttamente al posto giusto ..",
      "trainingSelection": "Seleziona la tua formazione",
      "yourMessage": "Il tuo messaggio",
      "success": "Il tuo messaggio è stato spedito.",
      "otherReason": "Richiesta generica di contatto",
      "otherReasonSubject": "Altre richieste",
      "chooseReason": "Scegli"
    },
    "learningProfile": {
      "title": "Profilo di apprendimento",
      "unit": "Unità di apprendimento",
      "points": "Punteggio totale",
      "history": "Storia di apprendimento",
      "restoreFilter": "Ripristina filtri",
      "certificate": "Certificato",
      'downloadAll': 'Scaricare tutto',
      "withoutCategory": "Senza categoria",
      "tooltip01": "Tu hai",
      "tooltip02": "di",
      "tooltip03": "unità di apprendimento completate.",
      'gamification': {
        'badge':{
          'title': 'Auszeichnungen',
          'congratulations': 'Congratulazioni!',
          'achieved': 'Hai ricevuto un nuovo premio!'
        }
      }
    },
    "courseDetail": {
      "title": "Dettagli del corso",
      'titleProject': 'Dettagli del progetto',
      "back": "Torna alla lista del corso",
      "progress": "Progressi",
      "expiry": "valido fino",
      "classroomNo": "Numero di corso",
      "contactPerson": "Persona di contatto",
      "targetGroup": "Obiettivo di gruppo",
      "bonusPoints": "Punti bonus",
      "classroomContent": "Contenuti del corso",
      "webinarLight": "Informazioni sul corso online",
      "specials": "Particolari",
      "stornoConditions": "Regole di cancellazione",
      "meeting": "Date",
      'meetingBooking': 'Prenota le date',
      'meetingRequests': 'Date di richiesta',
      'freeDay': 'Free day',
      'freeDayInfo': 'I giorni liberi non sono elencati sopra',
      "speaker": "Insegnante / Presentatore",
      "location": "Luogo del corso",
      "requested": "RICHIESTO",
      "waitlist": "IN ATTESA",
      "fullyBooked": "NESSUN POSTO DISPONIBILE",
      "edit": "Modifica",
      "webinarSpeaker": "Presentatore",
      "docsAndLinks": "Documenti e collegamenti",
      "links": "Collegamenti",
      "webinar": {
        "host": "Seminari aperti",
        "state": {
          "ended": "Seminari finiti",
          "beforeStart": "Il corso inizia alle"
        }
      },
      "sidebar": {
        "price": "Prezzo p.P.",
        "duration": "Tempo di apprendimento",
        "to": "a",
        "numberOfParticipants": "Numero di partecipanti",
        "status": "Stato di registrazione",
        'totalStatus': 'Stato generale',
        'contentStatus': 'Stato della formazione'
      },
      "bookingInformation": {
        "title": "Informazioni di registrazione",
        "note": "Nota",
        "accounts": "Centro di costo",
        "invoiceRecipient": "Destinatario fattura",
        "invoiceAddress": "Indirizzo della fatturazione",
        "food": "Preferenza sul cibo",
        "arrival": "Arrivo",
        "arrivalType": {
          "none": "Non specificato",
          "car": "Auto",
          "train": "Treno",
          "plane": "Aereoplano"
        },
        "arrivalDate": "Giorno di arrivo",
        "departure": "Partenza",
        "departureDate": "Giorno di partenza",
        "flightNumber": "Numero del volo",
        "trainNumber": "Numero del treno",
        "railwayStation": "Stazione dei treni",
        'placeholder': {
          'departure_airport': 'Aeroporto di partenza',
          'destination_airport': 'Aeroporto di destinazione',
          'departure_trainstation': 'Stazione di partenza',
          'destination_trainstation': 'Stazione di destinazione',
          'departure_car': 'Luogo di partenza',
          'destination_car': 'Luogo di destinazione',
          'from': 'da',
          'to': 'a'
        },
        'weekdays': [
          'lun',
          'mar',
          'mer',
          'gio',
          'ven',
          'sab',
          'dom'
        ],
        'months': [
          "Gennaio",
          "Febbraio",
          "Marzo",
          "Aprile",
          "Maggio",
          "Giugno",
          "Luglio",
          "Agosto",
          "Settembre",
          "Ottobre",
          "Novembre",
          "Dicembre"
        ]
      },
      "reserve": {
        "title": "Non puoi partecipare all'appuntamento/i?",
        "text": "Prendi nota del tuo interesse! Ti terremo  informato sulle possibili nuove date.",
        "button": "Prenota",
        "prebooked": {
          "text": "La tua prenotazione per questo evento/corso è stata registrata."
        }
      },
      'downloads': {
        'modalHeadline': 'Scarica',
        'sidebarLink': 'Documento/i',
      }

    },
    "course": {
      "type": {
        "0": "Corso Online",
        "1": "Corso Online",
        "2": "Corso Online",
        "3": "Corso Online",
        "4": "Corso Online",
        "5": "Corso Online",
        "6": "Formazione in aula",
        "7": "Seminario online",
        "8": "Progetto",
        "undefined": "-"
      },
      "status": {
        "open": "Aperto",
        'sent': 'Sent',
        "passed": "Passato",
        'sent': 'Inviato',
        "failed": "Fallito",
        'participated': 'Participated',
        'notParticipated': 'Not participated',
        "expired": "Scaduto",
        "soonExpired": "Scadenza vicina",
        "booked": "Prenotato",
        "started": "In corso",
        "upcoming": "In arrivo",
        "running": "in corso",
        "completed": "Completato"
      },
      "notification": {
        "success1": "Hai completato il corso",
        "success2": "con successo!"
      },
      'warning': {
        'headline': 'Attenzione!',
        'body': {
          '1': 'È stata selezionata una lingua diversa dall\'ultima volta per questa formazione.',
          '2': 'Se si procede e si fa clic su "Avvia formazione", i progressi vengono azzerati.',
        },
        'buttons': {
          'start': 'Avvia formazione',
          'startPrevious': 'Selezionare la lingua precedente',
        }
      },
      "statusLabel": {
        "training": "Corso",
        'content': 'Contenuto',
        'attendance': 'Attendance',
        "exam": "Esame",
        "survey": "Feedback"
      },
      "start": {
        "survey": "Comincia il sondaggio",
        "exam": "Comincia l'esame"
      },
      "prefix": {
        "category": "Categoria",
        "state": "Stato",
        "type": "Tipo di corso"
      },
      "noAssigned": "Non ci sono corsi a te assegnati.",
      "noSolved": "\"Non hai completato alcun corso. Comincia ora i tuoi corsi entrando nell'area \"\"i miei corsi\"\"\"",
      "noAssignedBookable": "Non ci sono corsi prenotabili a te assegnati.",
      "noResult": "Non ci sono corsi che corrispondono ai tuoi criteri",
      "loadingFailed": "I dati richiesti non sono stati caricati a causa di un errore sconosciuto.",
      "noResultInCategory": "Nessun corso è assegnato a questa categoria.",
      "meeting": {
        "noMeeting": "nessun dato",
        "multipleMeetings": "Varie date"
      },
      "project": {
        "courses": "Corso"
      }
    },
    "login": {
      "error": "Nome utente e/o password non corretti!",
      "empty": "Perfavore inserisci tutti i campi richiesti.",
      "back": "Torna all'accesso",
      "description": "Perfavore inserisci nome utente o email per ricevere i tuoi dati di accesso.",
      "password-reset": {
        "description": "Perfavore selezione una nuova password per il tuo account.",
        "success": "La tua password è stata cambiata con successo.",
        "error": "La password non corrisponde."
      },
      "placeholder": "Nome utente / Indirizzo email",
      "button": "Dati di accesso richiesti",
      "success": "La richiesta è stata inviata con successo.",
      "errorForgot": "L'utente non corrisponde ai criteri ricercati.",
      "errorForgotSecurityQuestion": "La risposta da te inserita non è corretta!"
    },
    "registration": {
      "description": "Se non hai ancora un account, puoi semplicemente registrarti tramite il codice promozionale della tua azienda. Successivamente riceverai immediatamente una e-mail.",
      "actionCodeError": "Codice di registrazione non valido",
      "actionCodeLimitExceeded": "È stato superato il numero massimo di registrazioni possibili per il codice di registrazione inserito.",
      "registerDescription": "Perfavore compila il modulo. Riceverai i dati di accesso via email a breve.",
      "title": "Registrazione",
      "required": "Campi richiesti *",
      "emailError": "L'indirizzo e-mail da te inserito è già stato utilizzato.",
      "send": "Inserisci",
      "completeMessage": "La tua registrazione è avvenuta con successo!"
    },
    "dataProtection": {
      "accept": "Accetto",
      "acceptText": "SI, accetto ai termini e condizioni/Protezione dei dati."
    },
    "securityQuestion": {
      "title": "Domande di sicurezza",
      "chooseQuestion": "Perfavore scegli",
      "questionSelection": "Perfavore scegli una domanda",
      "yourAnswer": "Perfavore inserisci una risposta",
      "description": "Rispondi alla domanda di sicurezza e seleziona una nuova password per il tuo account utente."
    },
    'faq': {
      'title': 'Domande frequenti'
    }
  },
  "sidebar": {
    "all": "Tutto",
    "categories": "CATEGORIE",
    "status": "STATO",
    "courseType": "TIPO DI CORSO"
  },
  "placeholder": {
    "username": "Nome utente",
    "password": "Password",
    "actionCode": "Codice promozionale"
  },
  "button": {
    "login": "Accedi",
    "login-sso": "DIRECTORY DI ACCESSO",
    "send": "INVIA",
    "start_course": "INIZIA",
    "changePassword": "Cambia Password",
    "reset": "Ripristina",
    "save": "Salva",
    "request": "Richiesta",
    "storno": "Cancella",
    "close": "Chiudi",
    "booking": "Ordina",
    "waitlist": "Lista di attesa",
    "start_webinar": "Inizia seminario",
    "open_webinar": "Seminari aperti",
    'cancel': 'Cancellare',
    'confirm': 'Confermare'
  },
  "bookingState": {
    "0": "RICHIESTA",
    "1": "PRENOTATO",
    "2": "LISTA DI ATTESA",
    "3": "CANCELLATA",
    "4": "CANCELLATA",
    "5": "RIFIUTATA",
    "6": "DATI SALVATI",
    "onlineTraining": {
      "0": "RICHIESTO",
      "1": "PRENOTATO",
      "2": "RIFIUTATO"
    }
  },
  "foodPreference": {
    "none": "Nessuno",
    "lactoseIntolerance": "Intolleranza al lattosio",
    "vegetarian": "Vegetariano",
    "vegan": "Vegano",
    "nutAllergy": "Allergico alle Noci",
    "diabetic": "Diabetico",
    "glutenFree": "Senza glutine",
    "kosher": "Kosher",
    "halal": "Halal",
    "other": "Altro"
  },
  "notifications": {
    "error": "Qualcosa è andato storto!",
    "errors": {
      "missingInput": "Si prega di compilare i capi",
      "imageLoad": "L'immagine richiesta non può essere caricata."
    },
    "success": {
      "book": {
        "meeting": "Hai prenotato con successo il corso in aula.",
        "webinar": "Hai prenotato con successo il seminario online.",
        "course": "Hai prenotato con successo il training.",
        "project": "Hai prenotato con successo il progetto."
      },
      "request": {
        "meeting": "La tua richiesta di prenotazione per il corso in aula è stata ricevuta.",
        "webinar": "La tua richiesta di prenotazione per il seminario online è stata ricevuta.",
        "course": "La tua richiesta di prenotazione per il corso è stata ricevuta.",
        "project": "La tua richiesta di prenotazione per il progettoe è stata ricevuta."
      },
      "profile": {
        "updateSuccess": "Hai aggiornato correttamente il tuo profilo!",
        "updatePasswordSuccess": "Hai aggiornato correttamente la tua password!"
      },
      "securityQuestion": {
        "questionSave": "La tua domanda di sicurezza è stata salvata correttamente!"
      }
    }
  },
  "webservice": {
    "error": {
      "duplicate": "La prenotazione è già presente!",
      "noaccess": "Accesso non autorizzato.",
      "notimplementet": "La funzione non è disponibile al momento."
    },
    "success": {
      "booking": "Hai prenotato correttamente.",
      "request": "Hai inviato correttamente la richiesta di prenotazione,",
      "cancel": "Cancellazione riuscita.",
      "waitlist": {
        "add": "Sei in lista di attesa"
      },
      "reservelist": {
        "add": "Sei stato inserito in elenco"
      }
    },
    "courseCalendar": {
      "noDate": "Senza data di scadenza"
    }
  },
  "trainingmanager": {
    "headline": "Finanziamenti per l'istruzione superiore",
    "headlinerequest": "applicazione di file",
    "bottonrequest": "Inserisci una richiesta",
    "buttonmanage": "Gestisci le tue richieste",
    "requestform": {
      "requester": "Richiedente:",
      "date": "Data:",
      "tab01": "Inserisci richiesta",
      "tab02": "Richieste di gruppo / di altri",
      "tab01headline01": "Nome del partecipante:",
      "tab01headline02": "Argomento del corso:",
      "tab01headline04": "Nomi dei partecipanti:",
      "tab01headline03": "Dettagli del corso:",
      "tab01label01": "Saluti:",
      "tab01label02": "Nome:",
      "tab01label03": "Cognome:",
      "tab01label04": "Signor",
      "tab01label05": "Signora",
      "tab01label06": "Categoria:",
      "tab01label07": "Titolo*:",
      "tab01label08": "Descrizione breve:",
      "tab01label09": "Altro:",
      "tab01label09O": "Altro",
      "tab01label10": "Centro di costo:",
      "tab01label11": "Data (da/a):*",
      "tab01label12": "Numero del corso:",
      "tab01label13": "Prezzo:",
      "tab01label14": "Fornitore:",
      "tab01label15": "Allegati:",
      "tab01label16": "Seleziona il file",
      "tab01label17": "Nessun file selezionato…",
      "tab01label18": "Inserisci richiesta",
      "tab01label19": "Aggiongi una persona",
      "tab01label20": "Cancella",
      "olheadline": "Aggiungi partecipante",
      "olheadline1": "Nome del partecipante",
      "olbodytext": "Seleziona un'altra partecipante dalla lista sottostante",
      "olerror": "Nessun partecipante trovato. Ti preghiamo di modificare i paramentri di ricerca."
    },
    "manage": {
      "headline": "Gestisci le tue richieste",
      "headlineInfo": "Richieste di gruppo",
      "bodytextInfo": "Di seguito troverai tutte le richieste di formazione aperte con la richiesta di elaborazione.",
      "newRequest": "Inserisci la tua richiesta",
      "tableHeadline01": "Entrata",
      "tableHeadline02": "Richiedente",
      "tableHeadline03": "Numero del corso",
      "tableHeadline04": "Titolo",
      "tableHeadline05": "Data",
      "tableHeadline06": "Prezzo",
      "tableMark": "Seleziona tutto",
      "buttonapprove": "Approva",
      "buttondecline": "Declina",
      "modal": {
        "refuse": {
          "headline": "Messaggio - richiesta declianta",
          "bodytext": "",
          "placeholder": "Perfavore inserisci testo…",
          "checkbox": "Rifiuta senza messaggio",
          "button": "Invia",
          "approval": "Richiesta approvata",
          "refuse": "Richiesta rifiutata"
        }
      }
    }
  },
  'projectBookingModal': {
    'headlines': {
      'chooseMeeting': 'Scegliere la data',
      'summaryMeeting': 'Panoramica degli appuntamenti',
      'summary': 'Sintesi',
      'online': 'Panoramica',
      'mixed': 'Panoramica',
      'information': 'Opzioni di prenotazione'
    },
    'description': 'Si prega di verificare le informazioni selezionate per la prenotazione prima di inviarle.'
  },
  'multiModal': {
    'buttons': {
      'cancel': 'Annullamento',
      'next': 'Di più',
      'back': 'Indietro',
      'send': 'Invia'
    }
  }
}
