// Ukrainisch

module.exports = {
  "main_navigation": {
    "login": "Вхід",
    "registration": "Реєстрація",
    "courses": "Мої тренінги",
    "coursesDetail": "Деталі тренінгу",
    "catalog": "Каталог",
    "learningProfile": "Навчальний профіль",
    "contact": "Контакт",
    "profile": "Профіль",
    "pwLost": "Забули дані для входу?",
    "about": "Вихідні дані",
    "terms_and_conditions": "Умови користування / Захист даних",
    "logout": "Вийти",
    "faq": "ЧАСТО ЗАДАВАНІ ПИТАННЯ",
    "trainingmanager": "Заяви",
    "trainingmanagerrequest": "Запит",
    "trainingmanagermanage": "Керувати",
    "trainingmanagermanagedetail": "Деталі"
  },
  "general": {
    'offline': {
      'headline': 'Ви офлайн!',
      'text': 'Для належної роботи цій програмі потрібне активне підключення до Інтернету.',
    },
    "year": "Рік",
    "month": "Місяць",
    "noResult": "Жодного результату не було знайдено.",
    "months": {
      "10": "Жовтень",
      "11": "Листопад",
      "12": "Грудень",
      "01": "Січень",
      "02": "Лютий",
      "03": "Березень",
      "04": "Квітень",
      "05": "Травень",
      "06": "Червень",
      "07": "Липень",
      "08": "Серпень",
      "09": "Вересень"
    },
    "category": "Категорія",
    "calendar": "Календар",
    "search": "Шукати",
    "searchError": "Введіть, будь ласка, щонайменше три знаки.",
    "showLess": "Показати менше",
    "showMore": "Показати більше"
  },
  "maintenance": {
    "title": "Роботи з обслуговування",
    "text": "Через проведення робіт з обслуговування, в даний час немає доступу до Академії. Будь ласка, спробуйте знову пізніше."
  },
  "password": {
    "requirements": {
      "title": "Принципи створення пароля",
      "minimalLength": "Довжина має становити не менше #0.",
      "mandantName": "Поняття \"#0\" використовувати не дозволяється.",
      "names": "У якості пароля не дозволяється використовувати ім'я або прізвище.",
      "userName": "Пароль не повинен містити твої імена користувача.",
      "userNameEqual": "Пароль не повинен містити твої імена користувача.",
      "bigLetters": "Має/мають бути в наявності щонайменше #0 великих літер.",
      "smallLetters": "Має/мають бути в наявності щонайменше #0 малих літер.",
      "digits": "Має/мають бути в наявності щонайменше #0 цифр.",
      "sameLetters": "Не дозволяється використовувати більше #0 знаків поспіль.",
      "oldPassword": "Не дозволяється використовувати старий пароль.",
      "birthday": "Пароль не повинен збігатися з датою власного народження.",
      "minSymbolsPolicy": "Має/мають бути в наявності щонайменше #0 спеціальних знаків.",
      "minPasswordLength": "Пароль повинен бути довжиною не менше ніж #0 знаків.",
      "maxPasswordAttempts": "У Вас залишилось ще #0 спроб для вводу правильного пароля."
    }
  },
  "pages": {
    "bossBooking": {
      "title": "Запит на резервування",
      "subTitle": {
        "accept": "Бажаєте прийняти запит на бронювання?",
        "decline": "Бажаєте відхилити запит на бронювання?"
      },
      "user": "Користувач",
      "training": "Тренінг",
      "meeting": "Дата",
      "time": "Час",
      "location": "Місце",
      "project": "Проект",
      "reason": "За бажанням вкажіть тут обґрунтування...",
      "accept": "Прийняти",
      "decline": "Відхилити",
      "home": "На домашню сторінку",
      "result": {
        "accept": "Запит на резервування прийнято.",
        "decline": "Запит на резервування відхилено.",
        "error": "Процес резервування не існує або вже завершено."
      }
    },
    "notFound": {
      "headline": "Нам прикро!",
      "text1": "Запитану сторінку не знайдено",
      "text2": "Використовуйте кнопку Вашого браузера «назад» або перейдіть до домашньої сторінки.",
      "button": "Назад на домашню сторінку"
    },
    "userNotFound": {
      "headline": "Під час Вашого входу сталася помилка!",
      "text": "Не знайдено користувача з Вашої організації. Спробуйте, будь ласка, ще раз пізніше або зв'яжіться з Вашою контактною особою або керівником.",
      "button": "Спробуйте знову"
    },
    "courseCalendar": {
      "expired": "Час закінчився",
      "soonExpired": "Скоро закінчується",
      'expiredMeetings': 'Попередні зустрічі'
    },
    "profile": {
      "userdata": "Дані користувача",
      "password": "Пароль",
      "genderLab": "Звертання",
      "gender": {
        "men": "Пан",
        "woman": "Пані",
        "diverse": "Інше",
        "unknown": "Дані відсутні"
      },
      "firstname": "Ім'я",
      "lastname": "Прізвище",
      "email": "E-Mail",
      "strasse": "Вулиця / №",
      "city": "Поштовий індекс / Місто",
      "phone": "Телефон",
      "fax": "Факс",
      "position": "Посада",
      "department": "Відділ",
      "required": "Поля, обов'язкові для заповнення",
      "oldPassword": "Старий пароль",
      "newPassword": "Новий пароль",
      "newPasswordRep": "Повторити",
      "company": "Компанія",
      "language": "Мова"
    },
    "catalog": {
      "back": "Назад",
      "bookingDialog": {
        "notice": "Введіть тут Ваше обґрунтування запиту на тренінг"
      }
    },
    "contact": {
      "info": "Будь ласка, введіть Ваш запит в поле для повідомлень. Якщо Ви посилаєтесь на спеціальний тренінг, будь ласка, вкажіть його: Завдяки цьому Ваше повідомлення може бути безпосередньо спрямовано до відповідної служби.",
      "trainingSelection": "Вибір тренінгу",
      "yourMessage": "Ваше повідомлення",
      "success": "Ваше повідомлення було успішно надіслано!",
      "otherReason": "Загальний запит на встановлення контакту",
      "otherReasonSubject": "Інший запит",
      "chooseReason": "Будь ласка, зробіть вибір"
    },
    "learningProfile": {
      "title": "Навчальний профіль",
      "unit": "Навчальні одиниці",
      "points": "Загальна кількість балів",
      "history": "Історія навчання",
      "restoreFilter": "Очистити фільтри",
      "certificate": "Сертифікат",
      "downloadAll": "Завантажити всі",
      "withoutCategory": "Без категорії",
      "tooltip01": "Ви закінчили",
      "tooltip02": "з",
      "tooltip03": "навчальних одиниць.",
      "gamification": {
        "badge": {
          "title": "Відзнаки",
          "congratulations": "Щиро вітаємо!",
          "achieved": "Ви отримали нову відзнаку!"
        }
      }
    },
    "courseDetail": {
      "noMeeting": "Дата для цього тренінгу ще не призначена або всі дати вже в минулому.",
      "title": "Деталі тренінгу",
      'titleProject': 'Деталі проекту',
      "back": "Назад до переліку",
      "progress": "Досягнутий результат",
      "expiry": "дійсний до",
      "classroomNo": "Номер навчального заходу",
      "contactPerson": "Контактна особа",
      "targetGroup": "Цільова група",
      "bonusPoints": "Бонусні бали",
      "classroomContent": "Зміст навчання",
      "webinarLight": "Інформація про вебінар",
      "specials": "Особливості",
      "stornoConditions": "Умови анулювання",
      "meeting": "Дати",
      'meetingBooking': 'Дати бронювання',
      'meetingRequests': 'Дати запиту',
      'freeDay': 'Free day',
      'freeDayInfo': 'Вільні дні вище не вказані\n',
      "speaker": "Викладач / Доповідач",
      "location": "Місце проведення навчання",
      "requested": "ЗАПИТ НАДІСЛАНО",
      "waitlist": "СПИСОК ОЧІКУВАННЯ",
      "fullyBooked": "ВІЛЬНИХ МІСЦЬ НЕМАЄ",
      "edit": "Обробити",
      "webinarSpeaker": "Доповідач",
      "docsAndLinks": "Документи і посилання",
      "deadline": "Припинення подання заявок",
      "webinar": {
        "host": "Відкрити вебінар",
        "state": {
          "ended": "Вебінар закінчений",
          "beforeStart": "Вебінар розпочинається о "
        }
      },
      "sidebar": {
        "price": "Ціна на одного учасника",
        "duration": "Тривалість навчання",
        "to": "до",
        "numberOfParticipants": "Кількість учасників",
        "status": "Статус бронювання",
        'totalStatus': 'загальний стан',
        'contentStatus': 'статус навчання'
      },
      "bookingInformation": {
        "title": "Інформація про бронювання",
        "note": "Примітка",
        "accounts": "Місце утворення витрат",
        "invoiceRecipient": "Отримувач рахунку",
        "invoiceAddress": "Адреса рахунку",
        "food": "Уподобання щодо обіднього меню",
        "arrival": "Схема проїзду",
        "arrivalType": {
          "none": "Дані відсутні",
          "car": "Автомобіль",
          "train": "Потяг",
          "plane": "Літак"
        },
        "arrivalDate": "Дата приїзду",
        "departure": "відправлення",
        "departureDate": "Дата від'їзду",
        "flightNumber": "Номер авіарейсу",
        "trainNumber": "номер поїзда",
        "railwayStation": "Вокзал",
        'placeholder': {
          'departure_airport': 'Аэропорт вылета',
          'destination_airport': 'Аэропорт назначения',
          'departure_trainstation': 'Железнодорожная станция отправления',
          'destination_trainstation': 'Железнодорожная станция назначения',
          'departure_car': 'Место отправления',
          'destination_car': 'Место назначения',
          'from': 'с сайта',
          'to': 'на'
        },
        'weekdays': [
          'ПО',
          'ВТ',
          'СР',
          'ЧТ',
          'ПТ',
          'СБ',
          'ВС'
        ],
        'months': [
          'Январь',
          'Февраль',
          'Март',
          'Апрель',
          'Май',
          'Июнь',
          'Июль',
          'Август',
          'Сентябрь',
          'Октябрь',
          'Ноябрь',
          'Декабрь'
        ]
      },
      "reserve": {
        "title": "Ви не можете узгодити дату/дати?",
        "text": "Додайте те, що Вас цікавить до обраного! Ви будете потім поінформовані про можливі нові дати.",
        "button": "Додати до обраних",
        "prebooked": {
          "text": "Ви відзначили свою зацікавленість цим тренінгом."
        }
      },
      "downloads": {
        "modalHeadline": "Завантаження",
        "sidebarLink": "Документ(и)"
      }
    },
    "course": {
      "type": {
        "0": "Онлайн-тренінг",
        "1": "Онлайн-тренінг",
        "2": "Онлайн-тренінг",
        "3": "Онлайн-тренінг",
        "4": "Онлайн-тренінг",
        "5": "Онлайн-тренінг",
        "6": "Очний тренінг",
        "7": "Вебінар",
        "8": "Проект",
        "undefined": "-"
      },
      "status": {
        "open": "Відкрито",
        "passed": "Успішно складено",
        "sent": "Відіслано",
        "failed": "Не складено",
        "expired": "Час закінчився",
        "soonExpired": "Скоро закінчується",
        "booked": "Зарезервовано",
        "started": "В обробці",
        "upcoming": "Заплановано",
        "running": "Виконується",
        "completed": "Завершено",
        "notyet": "Ще не доступно"
      },
      "notification": {
        "success1": "Ви ",
        "success2": " успішно пройшли тренінг!"
      },
      'warning': {
        'headline': 'Увага!',
        'body': {
          '1': 'Ви обрали іншу мову, ніж минулого разу для цього тренінгу.',
          '2': 'Якщо ви продовжите і натиснете на кнопку "Почати навчання", прогрес буде обнулений.',
        },
        'buttons': {
          'start': 'Почати навчання',
          'startPrevious': 'Виберіть попередню мову',
        }
      },
      "statusLabel": {
        "training": "Тренінг",
        "exam": "Іспит",
        "survey": "Опитування",
        "efficacyControl": "Контроль результативності"
      },
      "start": {
        "survey": "Розпочати опитування",
        "exam": "Розпочати іспит",
        "efficacyControl": "Розпочати контроль результативності"
      },
      "prefix": {
        "category": "Категорія",
        "state": "Статус",
        "type": "Тип тренінгу"
      },
      "noAssigned": "Вам не призначено жодного тренінгу.",
      "noSolved": "Ви не завершили ще жодного тренінгу. Розпочніть зараз в розділі «Мої тренінги» Вашу першу навчальну одиницю!",
      "noAssignedBookable": "Вам не призначено жодного тренінгу, що є доступним для резервування.",
      "noResult": "Не знайдено тренінгів, що відповідають критеріям.",
      "loadingFailed": "Запитані записи даних не вдалось завантажити через невідому помилку!",
      "noResultInCategory": "Цій категорії ще не були призначені тренінги.",
      "meeting": {
        "noMeeting": "відсутня дата",
        "multipleMeetings": "різні дати"
      },
      "project": {
        "courses": "тренінги"
      }
    },
    "login": {
      "invalidUsername": "Недійсний вхід. Ім'я користувача неправильне!",
      "invalidUser": "Недійсний вхід. Ім'я користувача / пароль неправильне(ий)!",
      "invalidPassword": "Недійсний вхід. У Вас залишилось ще #0 спроб для вводу правильного пароля.",
      "accountBlocked": "Ваш обліковий запис заблоковано. Зверніться, будь ласка, до відповідального співробітника.",
      "error": "Ім'я користувача та/або пароль неправильні!",
      "empty": "Будь ласка, заповніть всі поля.",
      "back": "Назад до входу",
      "description": "Будь ласка, введіть Ваше ім'я користувача або Вашу E-Mail адресу, щоб знову надіслати дані для доступу.",
      "password-reset": {
        "description": "Будь ласка, виберіть новий пароль для Вашого облікового запису користувача.",
        "success": "Ваш пароль було успішно змінено.",
        "error": "Вказані паролі не співпадають."
      },
      "placeholder": " Ім'я користувача / E-Mail адреса",
      "button": "Надіслати запит на дані для доступу",
      "success": "Запит було успішно надіслано!",
      "errorForgot": "Не знайдено користувача, який відповідає вказаним критеріям.",
      "errorForgotSecurityQuestion": "На жаль, відповідь неправильна!"
    },
    "registration": {
      "description": "Якщо Ви ще не маєте даних для входу, Ви можете просто зареєструватись через промокод Вашої компанії. Одразу після цього Вам буде надіслано повідомлення на Вашу електронну поштову скриньку.",
      "actionCodeError": "Уведений реєстраційний код недійсний",
      "actionCodeLimitExceeded": "Максимальна кількість можливих реєстрацій для введеного коду дії вичерпана.",
      "registerDescription": "Заповніть, будь ласка, наведений нижче формуляр. Ваші дані для доступу будуть надіслані Вам протягом кількох хвилин на електронну поштову скриньку.",
      "title": "Ваша реєстрація в Академії",
      "required": "Поле, обов'язкове для заповнення*",
      "emailError": "Введена Вами E-Mail адреса вже зайнята.",
      "emailErrorNotAllowed": "За цією E-Mail адресою реєстрація неможлива.",
      "send": "Відправити",
      "completeMessage": "Ви успішно зареєструвались в Академії!"
    },
    "dataProtection": {
      "accept": "Погодитись",
      "acceptText": "Так, я згоден з умовами користування / захисту даних"
    },
    "securityQuestion": {
      "title": "Запит на підтвердження",
      "chooseQuestion": "Будь ласка, зробіть вибір",
      "questionSelection": "Будь ласка, виберіть питання",
      "yourAnswer": "Будь ласка, введіть Вашу відповідь.",
      "description": "Будь ласка, надайте відповідь на запит на підтвердження і виберіть новий пароль для Вашого облікового запису користувача."
    },
    "faq": {
      "title": "Часто задавані питання"
    }
  },
  "sidebar": {
    "all": "Всі",
    "categories": "КАТЕГОРІЇ",
    "status": "СТАТУС",
    "courseType": "ТИП ТРЕНІНГУ",
    "topics": "ТЕМАТИЧНІ НАПРЯМИ"
  },
  "placeholder": {
    "username": "Ім'я користувача",
    "password": "Пароль",
    "actionCode": "Промокод"
  },
  "button": {
    "login": "УВІЙТИ В СИСТЕМУ",
    "login-sso": "ВХІД ДО АКТИВНОГО КАТАЛОГУ",
    "send": "ВІДПРАВИТИ",
    "start_course": "ПОЧАТОК",
    "changePassword": "Змінити пароль",
    "reset": "Скинути",
    "save": "Запам'ятати",
    "request": "Запит",
    "cancel": "Відмінити",
    "canceled": "Дата відмінена",
    "close": "Закрити",
    "booking": "Зарезервувати",
    "waitlist": "Список очікування",
    "start_webinar": "Розпочати вебінар",
    "open_webinar": "Відкрити вебінар"
  },
  "bookingState": {
    "0": "ЗАПИТ НАДІСЛАНО",
    "1": "ЗАРЕЗЕРВОВАНО",
    "2": "СПИСОК ОЧІКУВАННЯ",
    "3": "ВІДМІНЕНО",
    "4": "ВІДМІНЕНО",
    "5": "ВІДХИЛЕНО",
    "6": "ДОДАНО ДО ОБРАНИХ",
    "onlineTraining": {
      "0": "ЗАПИТ НАДІСЛАНО",
      "1": "ЗАРЕЗЕРВОВАНО",
      "2": "ВІДХИЛЕНО"
    }
  },
  "foodPreference": {
    "none": "Відсутні",
    "lactoseIntolerance": "Непереносимість лактози",
    "vegetarian": "Вегетаріанська їжа",
    "vegan": "Веганська їжа",
    "nutAllergy": "Алергія на горіхи",
    "diabetic": "Діабет",
    "glutenFree": "Безглутенова їжа",
    "kosher": "Кошерна їжа",
    "halal": "Халяльна їжа",
    "other": "Інше"
  },
  "notifications": {
    "error": "Щось пішло не так!",
    "errors": {
      "missingInput": "Будь ласка, заповніть ці поля.",
      "imageLoad": "Запитане зображення не вдалося завантажити"
    },
    "success": {
      "book": {
        "meeting": "Ви успішно зарезервували очний тренінг!",
        "webinar": "Ви успішно зарезервували вебінар.",
        "course": "Ви успішно зарезервували тренінг!",
        "project": "Ви успішно зарезервували проект!"
      },
      "request": {
        "meeting": "Ви успішно надіслали запит на очний тренінг!",
        "webinar": "Ви успішно надіслали запит на вебінар.",
        "course": "Ви успішно надіслали запит на тренінг!",
        "project": "Ви успішно надіслали запит на проект!"
      },
      "profile": {
        "updateSuccess": "Ви успішно оновили Ваш профіль",
        "updatePasswordSuccess": "Ви успішно оновили Ваш пароль"
      },
      "securityQuestion": {
        "questionSave": "Ваше контрольне запитання збережено!"
      }
    }
  },
  "webservice": {
    "error": {
      "duplicate": "Це резервування вже існує!",
      "noaccess": "Доступ не дозволено.",
      "notimplementet": "Функція зараз недоступна."
    },
    "success": {
      "booking": "Ви успішно виконали резервування.",
      "request": "Ви успішно надіслали запит.",
      "cancel": "Ви успішно зробили відміну. ",
      "waitlist": {
        "add": "Вас додали до списку очікування"
      },
      "reservelist": {
        "add": "Вас додали до списку зацікавлених"
      }
    },
    "courseCalendar": {
      "noDate": "Без терміну"
    }
  },
  "trainingmanager": {
    "headline": "Запит на підвищення кваліфікації",
    "headlinerequest": "Надіслати заяву",
    "bottonrequest": "Надіслати заяву",
    "buttonmanage": "Керувати заявами",
    "requestform": {
      "requester": "Заявник:",
      "date": "Дата:",
      "tab01": "Надіслати запит",
      "tab02": "Запит для групи/третіх осіб",
      "tab01headline01": "Прізвище учасника:",
      "tab01headline02": "Тема підвищення кваліфікації:",
      "tab01headline04": "Прізвища учасників:",
      "tab01headline03": "Деталі курсу:",
      "tab01label01": "Звертання:",
      "tab01label02": "Ім'я:",
      "tab01label03": "Прізвище:",
      "tab01label04": "Пан",
      "tab01label05": "Пані",
      "tab01label06": "Категорія:",
      "tab01label07": "Звання*:",
      "tab01label08": "Короткий опис:",
      "tab01label09": "Інше:",
      "tab01label09O": "Інше",
      "tab01label10": "Місце утворення витрат:",
      "tab01label11": "Дата (з/до):*",
      "tab01label12": "Номер курсу:",
      "tab01label13": "Ціна:",
      "tab01label14": "Постачальник послуг:",
      "tab01label15": "Додаток:",
      "tab01label16": "Вибрати файл",
      "tab01label17": "Не вибрано жодного файлу...",
      "tab01label18": "Відіслати заяву",
      "tab01label19": "Додати інших осіб",
      "tab01label20": "Припинити",
      "olheadline": "Додати учасників",
      "olheadline1": "Прізвище учасника",
      "olbodytext": "Виберіть далі прізвища інших учасників тренінгу.",
      "olerror": "Жодного учасника не було знайдено. Будь ласка, змініть параметри пошуку."
    },
    "manage": {
      "headline": "Керувати заявами",
      "headlineInfo": "Запити співробітників",
      "bodytextInfo": "Далі Ви знайдете усі відкриті запити на підвищення кваліфікації з проханням на обробку.",
      "newRequest": "Відіслати власну заяву",
      "tableHeadline01": "Вхід",
      "tableHeadline02": "Заявник",
      "tableHeadline03": "№ курсу",
      "tableHeadline04": "Звання",
      "tableHeadline05": "Дата",
      "tableHeadline06": "Ціна",
      "tableMark": "Позначити всі",
      "buttonapprove": "Надати дозвіл",
      "buttondecline": "Не надавати дозвіл",
      "modal": {
        "refuse": {
          "headline": "Повідомлення - відхилити запит",
          "bodytext": "",
          "placeholder": "Будь ласка, введіть текст....",
          "checkbox": "Відхилити запит без повідомлення",
          "button": "Відіслати",
          "approval": "Дозвіл надано",
          "refuse": "Дозвіл не надано"
        }
      }
    }
  },
  'projectBookingModal': {
    'headlines': {
      'chooseMeeting': 'Оберіть дату',
      'summaryMeeting': 'Огляд призначень',
      'summary': 'Резюме',
      'online': 'Огляд',
      'mixed': 'Огляд',
      'information': 'Варіанти бронювання'
    },
    'description': 'Будь ласка, перевірте обрану вами інформацію про бронювання перед відправкою.'
  },
  'multiModal': {
    'buttons': {
      'cancel': 'Скасувати',
      'next': 'Далі',
      'back': 'Назад',
      'send': 'Назад'
    }
  }
}
